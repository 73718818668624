'use strict';

var cov_19arb0aou8 = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/main/main.factory.js',
      hash = '904c08f2bc6379ce650163bd0cd46ed5c3d6d564',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/main/main.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 39
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 20,
          column: 4
        }
      },
      '2': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 12,
          column: 9
        }
      },
      '3': {
        start: {
          line: 15,
          column: 6
        },
        end: {
          line: 18,
          column: 9
        }
      }
    },
    fnMap: {
      '0': {
        name: 'MainFactory',
        decl: {
          start: {
            line: 6,
            column: 9
          },
          end: {
            line: 6,
            column: 20
          }
        },
        loc: {
          start: {
            line: 6,
            column: 33
          },
          end: {
            line: 21,
            column: 1
          }
        },
        line: 6
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 8,
            column: 15
          },
          end: {
            line: 8,
            column: 16
          }
        },
        loc: {
          start: {
            line: 8,
            column: 21
          },
          end: {
            line: 13,
            column: 5
          }
        },
        line: 8
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 14,
            column: 12
          },
          end: {
            line: 14,
            column: 13
          }
        },
        loc: {
          start: {
            line: 14,
            column: 18
          },
          end: {
            line: 19,
            column: 5
          }
        },
        line: 14
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_19arb0aou8.s[0]++;
angular.module('app').factory('MainFactory', MainFactory);

/** @ngInject */
function MainFactory(Api, $http) {
  cov_19arb0aou8.f[0]++;
  cov_19arb0aou8.s[1]++;

  return {
    getOffers: function getOffers() {
      cov_19arb0aou8.f[1]++;
      cov_19arb0aou8.s[2]++;

      return $http({
        url: Api.generate('products', 'offers'),
        method: 'POST'
      });
    },
    getNew: function getNew() {
      cov_19arb0aou8.f[2]++;
      cov_19arb0aou8.s[3]++;

      return $http({
        url: Api.generate('products', 'new'),
        method: 'POST'
      });
    }
  };
}