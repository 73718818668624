'use strict';

var cov_11mh1azk6q = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/product-page/product.js',
      hash = '36b9918db3aa97cc85cd4b3572c105cea5091f70',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/product-page/product.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 19
        }
      },
      '1': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 99,
          column: 9
        }
      },
      '2': {
        start: {
          line: 8,
          column: 8
        },
        end: {
          line: 8,
          column: 39
        }
      },
      '3': {
        start: {
          line: 9,
          column: 21
        },
        end: {
          line: 9,
          column: 23
        }
      },
      '4': {
        start: {
          line: 10,
          column: 25
        },
        end: {
          line: 10,
          column: 27
        }
      },
      '5': {
        start: {
          line: 13,
          column: 8
        },
        end: {
          line: 25,
          column: 9
        }
      },
      '6': {
        start: {
          line: 14,
          column: 10
        },
        end: {
          line: 14,
          column: 39
        }
      },
      '7': {
        start: {
          line: 15,
          column: 10
        },
        end: {
          line: 15,
          column: 40
        }
      },
      '8': {
        start: {
          line: 16,
          column: 10
        },
        end: {
          line: 16,
          column: 54
        }
      },
      '9': {
        start: {
          line: 18,
          column: 10
        },
        end: {
          line: 18,
          column: 55
        }
      },
      '10': {
        start: {
          line: 19,
          column: 10
        },
        end: {
          line: 19,
          column: 41
        }
      },
      '11': {
        start: {
          line: 20,
          column: 10
        },
        end: {
          line: 23,
          column: 11
        }
      },
      '12': {
        start: {
          line: 21,
          column: 12
        },
        end: {
          line: 21,
          column: 41
        }
      },
      '13': {
        start: {
          line: 22,
          column: 12
        },
        end: {
          line: 22,
          column: 42
        }
      },
      '14': {
        start: {
          line: 24,
          column: 10
        },
        end: {
          line: 24,
          column: 54
        }
      },
      '15': {
        start: {
          line: 27,
          column: 25
        },
        end: {
          line: 27,
          column: 67
        }
      },
      '16': {
        start: {
          line: 28,
          column: 29
        },
        end: {
          line: 30,
          column: 20
        }
      },
      '17': {
        start: {
          line: 29,
          column: 10
        },
        end: {
          line: 29,
          column: 39
        }
      },
      '18': {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 35,
          column: 13
        }
      },
      '19': {
        start: {
          line: 34,
          column: 12
        },
        end: {
          line: 34,
          column: 42
        }
      },
      '20': {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 40,
          column: 13
        }
      },
      '21': {
        start: {
          line: 39,
          column: 12
        },
        end: {
          line: 39,
          column: 47
        }
      },
      '22': {
        start: {
          line: 42,
          column: 8
        },
        end: {
          line: 45,
          column: 13
        }
      },
      '23': {
        start: {
          line: 44,
          column: 12
        },
        end: {
          line: 44,
          column: 43
        }
      },
      '24': {
        start: {
          line: 51,
          column: 8
        },
        end: {
          line: 51,
          column: 47
        }
      },
      '25': {
        start: {
          line: 53,
          column: 8
        },
        end: {
          line: 57,
          column: 10
        }
      },
      '26': {
        start: {
          line: 59,
          column: 8
        },
        end: {
          line: 61,
          column: 11
        }
      },
      '27': {
        start: {
          line: 60,
          column: 10
        },
        end: {
          line: 60,
          column: 33
        }
      },
      '28': {
        start: {
          line: 63,
          column: 8
        },
        end: {
          line: 68,
          column: 9
        }
      },
      '29': {
        start: {
          line: 64,
          column: 10
        },
        end: {
          line: 64,
          column: 56
        }
      },
      '30': {
        start: {
          line: 65,
          column: 10
        },
        end: {
          line: 65,
          column: 74
        }
      },
      '31': {
        start: {
          line: 67,
          column: 10
        },
        end: {
          line: 67,
          column: 38
        }
      },
      '32': {
        start: {
          line: 71,
          column: 8
        },
        end: {
          line: 71,
          column: 27
        }
      },
      '33': {
        start: {
          line: 72,
          column: 8
        },
        end: {
          line: 78,
          column: 11
        }
      },
      '34': {
        start: {
          line: 73,
          column: 10
        },
        end: {
          line: 77,
          column: 12
        }
      },
      '35': {
        start: {
          line: 79,
          column: 8
        },
        end: {
          line: 98,
          column: 10
        }
      },
      '36': {
        start: {
          line: 101,
          column: 4
        },
        end: {
          line: 106,
          column: 6
        }
      },
      '37': {
        start: {
          line: 102,
          column: 22
        },
        end: {
          line: 104,
          column: 8
        }
      },
      '38': {
        start: {
          line: 103,
          column: 8
        },
        end: {
          line: 103,
          column: 34
        }
      },
      '39': {
        start: {
          line: 105,
          column: 6
        },
        end: {
          line: 105,
          column: 58
        }
      },
      '40': {
        start: {
          line: 108,
          column: 4
        },
        end: {
          line: 113,
          column: 6
        }
      },
      '41': {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 111,
          column: 9
        }
      },
      '42': {
        start: {
          line: 110,
          column: 8
        },
        end: {
          line: 110,
          column: 27
        }
      },
      '43': {
        start: {
          line: 112,
          column: 6
        },
        end: {
          line: 112,
          column: 24
        }
      },
      '44': {
        start: {
          line: 117,
          column: 0
        },
        end: {
          line: 123,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 59
          },
          end: {
            line: 114,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 7,
            column: 12
          },
          end: {
            line: 7,
            column: 13
          }
        },
        loc: {
          start: {
            line: 7,
            column: 19
          },
          end: {
            line: 99,
            column: 7
          }
        },
        line: 7
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 28,
            column: 45
          },
          end: {
            line: 28,
            column: 46
          }
        },
        loc: {
          start: {
            line: 28,
            column: 50
          },
          end: {
            line: 30,
            column: 9
          }
        },
        line: 28
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 33,
            column: 16
          },
          end: {
            line: 33,
            column: 17
          }
        },
        loc: {
          start: {
            line: 33,
            column: 23
          },
          end: {
            line: 35,
            column: 11
          }
        },
        line: 33
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 38,
            column: 16
          },
          end: {
            line: 38,
            column: 17
          }
        },
        loc: {
          start: {
            line: 38,
            column: 23
          },
          end: {
            line: 40,
            column: 11
          }
        },
        line: 38
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 43,
            column: 16
          },
          end: {
            line: 43,
            column: 17
          }
        },
        loc: {
          start: {
            line: 43,
            column: 23
          },
          end: {
            line: 45,
            column: 11
          }
        },
        line: 43
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 59,
            column: 50
          },
          end: {
            line: 59,
            column: 51
          }
        },
        loc: {
          start: {
            line: 59,
            column: 55
          },
          end: {
            line: 61,
            column: 9
          }
        },
        line: 59
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 72,
            column: 30
          },
          end: {
            line: 72,
            column: 31
          }
        },
        loc: {
          start: {
            line: 72,
            column: 40
          },
          end: {
            line: 78,
            column: 9
          }
        },
        line: 72
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 101,
            column: 20
          },
          end: {
            line: 101,
            column: 21
          }
        },
        loc: {
          start: {
            line: 101,
            column: 33
          },
          end: {
            line: 106,
            column: 5
          }
        },
        line: 101
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 102,
            column: 44
          },
          end: {
            line: 102,
            column: 45
          }
        },
        loc: {
          start: {
            line: 102,
            column: 49
          },
          end: {
            line: 104,
            column: 7
          }
        },
        line: 102
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 108,
            column: 21
          },
          end: {
            line: 108,
            column: 22
          }
        },
        loc: {
          start: {
            line: 108,
            column: 34
          },
          end: {
            line: 113,
            column: 5
          }
        },
        line: 108
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 109,
            column: 37
          },
          end: {
            line: 109,
            column: 38
          }
        },
        loc: {
          start: {
            line: 109,
            column: 42
          },
          end: {
            line: 111,
            column: 7
          }
        },
        line: 109
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 13,
            column: 8
          },
          end: {
            line: 25,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 13,
            column: 8
          },
          end: {
            line: 25,
            column: 9
          }
        }, {
          start: {
            line: 13,
            column: 8
          },
          end: {
            line: 25,
            column: 9
          }
        }],
        line: 13
      },
      '1': {
        loc: {
          start: {
            line: 20,
            column: 10
          },
          end: {
            line: 23,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 20,
            column: 10
          },
          end: {
            line: 23,
            column: 11
          }
        }, {
          start: {
            line: 20,
            column: 10
          },
          end: {
            line: 23,
            column: 11
          }
        }],
        line: 20
      },
      '2': {
        loc: {
          start: {
            line: 63,
            column: 8
          },
          end: {
            line: 68,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 63,
            column: 8
          },
          end: {
            line: 68,
            column: 9
          }
        }, {
          start: {
            line: 63,
            column: 8
          },
          end: {
            line: 68,
            column: 9
          }
        }],
        line: 63
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Product =
/** @ngInject */
function Product(ProductFactory, $stateParams, $cookies, env) {
  _classCallCheck(this, Product);

  cov_11mh1azk6q.f[0]++;

  var vm = (cov_11mh1azk6q.s[0]++, this);

  cov_11mh1azk6q.s[1]++;
  ProductFactory.one({ slug: $stateParams.slug }).then(function (rsp) {
    cov_11mh1azk6q.f[1]++;
    cov_11mh1azk6q.s[2]++;

    vm.product = rsp.data.response;
    var recent = (cov_11mh1azk6q.s[3]++, []);
    var recentText = (cov_11mh1azk6q.s[4]++, '');

    // store cookie for recent viewed
    cov_11mh1azk6q.s[5]++;
    if (typeof $cookies.get('recent-products') === 'undefined') {
      cov_11mh1azk6q.b[0][0]++;
      cov_11mh1azk6q.s[6]++;

      recent.push(vm.product.slug);
      cov_11mh1azk6q.s[7]++;
      recentText = recent.join(',');
      cov_11mh1azk6q.s[8]++;
      $cookies.put('recent-products', recentText);
    } else {
      cov_11mh1azk6q.b[0][1]++;
      cov_11mh1azk6q.s[9]++;

      recentText = $cookies.get('recent-products');
      cov_11mh1azk6q.s[10]++;
      recent = recentText.split(',');
      cov_11mh1azk6q.s[11]++;
      if (recent.indexOf(vm.product.slug) === -1) {
        cov_11mh1azk6q.b[1][0]++;
        cov_11mh1azk6q.s[12]++;

        recent.push(vm.product.slug);
        cov_11mh1azk6q.s[13]++;
        recentText = recent.join(',');
      } else {
        cov_11mh1azk6q.b[1][1]++;
      }
      cov_11mh1azk6q.s[14]++;
      $cookies.put('recent-products', recentText);
    }

    var sendData = (cov_11mh1azk6q.s[15]++, $cookies.get('recent-products').split(','));
    var sendDataText = (cov_11mh1azk6q.s[16]++, sendData.filter(function (o) {
      cov_11mh1azk6q.f[2]++;
      cov_11mh1azk6q.s[17]++;

      return o !== vm.product.slug;
    }).join(','));

    cov_11mh1azk6q.s[18]++;
    ProductFactory.recent({ slugs: sendDataText }).then(function (rsp) {
      cov_11mh1azk6q.f[3]++;
      cov_11mh1azk6q.s[19]++;

      vm.recent = rsp.data.response;
    });

    cov_11mh1azk6q.s[20]++;
    ProductFactory.recommended({ slug: $stateParams.slug }).then(function (rsp) {
      cov_11mh1azk6q.f[4]++;
      cov_11mh1azk6q.s[21]++;

      vm.recommended = rsp.data.response;
    });

    cov_11mh1azk6q.s[22]++;
    ProductFactory.matches({ slug: $stateParams.slug }).then(function (rsp) {
      cov_11mh1azk6q.f[5]++;
      cov_11mh1azk6q.s[23]++;

      vm.matches = rsp.data.response;
    });

    // vm.primaryImage = vm.product.images.find(o => {
    //   return o.primary === 1;
    // });

    cov_11mh1azk6q.s[24]++;
    vm.primaryImage = vm.product.images[0];

    cov_11mh1azk6q.s[25]++;
    vm.selected = {
      large: vm.primaryImage.original,
      thumb: vm.primaryImage.small,
      medium: vm.primaryImage.large_constrained
    };

    cov_11mh1azk6q.s[26]++;
    vm.smallImages = vm.product.images.filter(function (o) {
      cov_11mh1azk6q.f[6]++;
      cov_11mh1azk6q.s[27]++;

      return o.primary === 0;
    });

    cov_11mh1azk6q.s[28]++;
    if (vm.product.sizes.length > 0) {
      cov_11mh1azk6q.b[2][0]++;
      cov_11mh1azk6q.s[29]++;

      vm.firstSize = String(vm.product.sizes[0].id);
      cov_11mh1azk6q.s[30]++;
      vm.price = vm.product.sizes[0].price + ' ' + env.currency.label;
    } else {
      cov_11mh1azk6q.b[2][1]++;
      cov_11mh1azk6q.s[31]++;

      vm.price = vm.product.price;
    }

    // convert images to zoom object
    cov_11mh1azk6q.s[32]++;
    vm.zoomImages = [];
    cov_11mh1azk6q.s[33]++;
    vm.product.images.map(function (o, i) {
      cov_11mh1azk6q.f[7]++;
      cov_11mh1azk6q.s[34]++;

      vm.zoomImages[i] = {
        thumb: o.small,
        medium: o.large_constrained,
        large: o.original
      };
    });
    cov_11mh1azk6q.s[35]++;
    vm.options = {
      zoomEnable: true,
      defaultIndex: 0, // Order of the default selected Image
      /* You should give images in array with object properties
       [{
       thumb : 'assets/images/1-thumb.jpg',
       medium: 'assets/images/1-medium.jpg',
       large : 'assets/images/1-large.jpg'
       }] */
      images: vm.zoomImages,
      style: 'box', // inner or box
      boxPos: 'right-top', // e.g., right-top, right-middle, right-bottom, top-center, top-left, top-right ...
      boxW: 550, // Box width
      boxH: 400, // Box height
      method: 'lens', // follow 'lens' or 'pointer'
      thumbCol: 5,
      cursor: 'crosshair', // 'none', 'default', 'crosshair', 'pointer', 'move'
      lens: true, // Lens toggle
      zoomLevel: 3 // 0: not scales, uses the original large image size, use 1 and above to adjust.
    };
  });

  cov_11mh1azk6q.s[36]++;
  vm.changeSize = function (a) {
    cov_11mh1azk6q.f[8]++;

    var newSize = (cov_11mh1azk6q.s[37]++, vm.product.sizes.find(function (o) {
      cov_11mh1azk6q.f[9]++;
      cov_11mh1azk6q.s[38]++;

      return String(o.id) === a;
    }));
    cov_11mh1azk6q.s[39]++;
    vm.price = newSize.price + ' ' + env.currency.label;
  };

  cov_11mh1azk6q.s[40]++;
  vm.selectColor = function (a) {
    cov_11mh1azk6q.f[10]++;
    cov_11mh1azk6q.s[41]++;

    vm.product.availableColors.map(function (o) {
      cov_11mh1azk6q.f[11]++;
      cov_11mh1azk6q.s[42]++;

      o.selected = false;
    });
    cov_11mh1azk6q.s[43]++;
    a.selected = true;
  };
};

cov_11mh1azk6q.s[44]++;


angular.module('app').component('product', {
  templateUrl: 'app/product-page/product.html',
  controller: Product,
  controllerAs: 'vm'
});