'use strict';

var cov_xa4e7tb07 = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/components/slider/slider.js',
      hash = '5de1e434bc8435b8e36be53707ab45fc81798653',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/components/slider/slider.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 5,
          column: 5
        }
      }
    },
    fnMap: {},
    branchMap: {},
    s: {
      '0': 0
    },
    f: {},
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_xa4e7tb07.s[0]++;
angular.module('app').component('cshopSlider', {
  templateUrl: 'app/components/slider/slider.html'
});