'use strict';

var cov_1peca8u6xn = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/components/page-banner/page-banner.js',
      hash = 'bce18b890a4f411f264c076e68907a17c8720855',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/components/page-banner/page-banner.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 19
        }
      },
      '1': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 39
        }
      },
      '2': {
        start: {
          line: 6,
          column: 4
        },
        end: {
          line: 6,
          column: 38
        }
      },
      '3': {
        start: {
          line: 10,
          column: 0
        },
        end: {
          line: 16,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 26
          },
          end: {
            line: 7,
            column: 3
          }
        },
        line: 3
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var PageBannerController =
/** @ngInject */
function PageBannerController($rootScope) {
  _classCallCheck(this, PageBannerController);

  cov_1peca8u6xn.f[0]++;

  var vm = (cov_1peca8u6xn.s[0]++, this);
  cov_1peca8u6xn.s[1]++;
  vm.title = $rootScope.productTitle;
  cov_1peca8u6xn.s[2]++;
  vm.category = $rootScope.category;
};

cov_1peca8u6xn.s[3]++;


angular.module('app').component('pageBanner', {
  templateUrl: 'app/components/page-banner/page-banner.html',
  controller: PageBannerController,
  controllerAs: 'vm'
});