'use strict';

var cov_2i3ie2g3rw = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/product-page/product.factory.js',
      hash = '2bb5fad0a77473779f428d182bac25d9ffe7ca57',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/product-page/product.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 45
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 43,
          column: 4
        }
      },
      '2': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 13,
          column: 9
        }
      },
      '3': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 20,
          column: 9
        }
      },
      '4': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 27,
          column: 9
        }
      },
      '5': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 34,
          column: 9
        }
      },
      '6': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 41,
          column: 9
        }
      }
    },
    fnMap: {
      '0': {
        name: 'ProductFactory',
        decl: {
          start: {
            line: 6,
            column: 9
          },
          end: {
            line: 6,
            column: 23
          }
        },
        loc: {
          start: {
            line: 6,
            column: 36
          },
          end: {
            line: 44,
            column: 1
          }
        },
        line: 6
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 8,
            column: 9
          },
          end: {
            line: 8,
            column: 10
          }
        },
        loc: {
          start: {
            line: 8,
            column: 17
          },
          end: {
            line: 14,
            column: 5
          }
        },
        line: 8
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 12
          },
          end: {
            line: 15,
            column: 13
          }
        },
        loc: {
          start: {
            line: 15,
            column: 20
          },
          end: {
            line: 21,
            column: 5
          }
        },
        line: 15
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 17
          },
          end: {
            line: 22,
            column: 18
          }
        },
        loc: {
          start: {
            line: 22,
            column: 25
          },
          end: {
            line: 28,
            column: 5
          }
        },
        line: 22
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 29,
            column: 13
          },
          end: {
            line: 29,
            column: 14
          }
        },
        loc: {
          start: {
            line: 29,
            column: 21
          },
          end: {
            line: 35,
            column: 5
          }
        },
        line: 29
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 36,
            column: 9
          },
          end: {
            line: 36,
            column: 10
          }
        },
        loc: {
          start: {
            line: 36,
            column: 17
          },
          end: {
            line: 42,
            column: 5
          }
        },
        line: 36
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2i3ie2g3rw.s[0]++;
angular.module('app').factory('ProductFactory', ProductFactory);

/** @ngInject */
function ProductFactory(Api, $http) {
  cov_2i3ie2g3rw.f[0]++;
  cov_2i3ie2g3rw.s[1]++;

  return {
    one: function one(data) {
      cov_2i3ie2g3rw.f[1]++;
      cov_2i3ie2g3rw.s[2]++;

      return $http({
        url: Api.generate('products', 'get-one'),
        method: 'POST',
        data: data
      });
    },
    recent: function recent(data) {
      cov_2i3ie2g3rw.f[2]++;
      cov_2i3ie2g3rw.s[3]++;

      return $http({
        url: Api.generate('products', 'recent'),
        method: 'POST',
        data: data
      });
    },
    recommended: function recommended(data) {
      cov_2i3ie2g3rw.f[3]++;
      cov_2i3ie2g3rw.s[4]++;

      return $http({
        url: Api.generate('products', 'recommended'),
        method: 'POST',
        data: data
      });
    },
    matches: function matches(data) {
      cov_2i3ie2g3rw.f[4]++;
      cov_2i3ie2g3rw.s[5]++;

      return $http({
        url: Api.generate('products', 'matches'),
        method: 'POST',
        data: data
      });
    },
    all: function all(data) {
      cov_2i3ie2g3rw.f[5]++;
      cov_2i3ie2g3rw.s[6]++;

      return $http({
        url: Api.generate('products', 'get-all'),
        method: 'POST',
        data: data
      });
    }
  };
}