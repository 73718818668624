'use strict';

var cov_aiwxtjhop = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/login-register/login-register.js',
      hash = '912b08655c6e8adec504eccfb4b7dd483f48dab5',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/login-register/login-register.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 19
        }
      },
      '1': {
        start: {
          line: 8,
          column: 0
        },
        end: {
          line: 14,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 16
          },
          end: {
            line: 5,
            column: 3
          }
        },
        line: 3
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Login =
/** @ngInject */
function Login() {
  _classCallCheck(this, Login);

  cov_aiwxtjhop.f[0]++;

  var vm = (cov_aiwxtjhop.s[0]++, this);
};

cov_aiwxtjhop.s[1]++;


angular.module('app').component('login', {
  templateUrl: 'app/login-register/login-register.html',
  controller: Login,
  controllerAs: 'vm'
});