'use strict';

var cov_10dxxs6nu3 = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/components/header/header.factory.js',
      hash = 'f6c202d249f8f7ad45c644e76e467b1bfd176ccb',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/components/header/header.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 51
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 14,
          column: 4
        }
      },
      '2': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 12,
          column: 9
        }
      }
    },
    fnMap: {
      '0': {
        name: 'CategoriesFactory',
        decl: {
          start: {
            line: 6,
            column: 9
          },
          end: {
            line: 6,
            column: 26
          }
        },
        loc: {
          start: {
            line: 6,
            column: 39
          },
          end: {
            line: 15,
            column: 1
          }
        },
        line: 6
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 8,
            column: 12
          },
          end: {
            line: 8,
            column: 13
          }
        },
        loc: {
          start: {
            line: 8,
            column: 18
          },
          end: {
            line: 13,
            column: 5
          }
        },
        line: 8
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_10dxxs6nu3.s[0]++;
angular.module('app').factory('CategoriesFactory', CategoriesFactory);

/** @ngInject */
function CategoriesFactory(Api, $http) {
  cov_10dxxs6nu3.f[0]++;
  cov_10dxxs6nu3.s[1]++;

  return {
    getAll: function getAll() {
      cov_10dxxs6nu3.f[1]++;
      cov_10dxxs6nu3.s[2]++;

      return $http({
        url: Api.generate('categories', 'read'),
        method: 'POST'
      });
    }
  };
}