'use strict';

var cov_ztnzvlc7k = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/components/small-product-box/small-product-box.js',
      hash = '0b39709bdae59eb8eee87724f07b8f63af7f8c5a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/components/small-product-box/small-product-box.js',
    statementMap: {
      '0': {
        start: {
          line: 4,
          column: 15
        },
        end: {
          line: 4,
          column: 19
        }
      },
      '1': {
        start: {
          line: 5,
          column: 4
        },
        end: {
          line: 5,
          column: 31
        }
      },
      '2': {
        start: {
          line: 7,
          column: 4
        },
        end: {
          line: 20,
          column: 6
        }
      },
      '3': {
        start: {
          line: 8,
          column: 6
        },
        end: {
          line: 10,
          column: 7
        }
      },
      '4': {
        start: {
          line: 9,
          column: 8
        },
        end: {
          line: 9,
          column: 146
        }
      },
      '5': {
        start: {
          line: 12,
          column: 6
        },
        end: {
          line: 14,
          column: 7
        }
      },
      '6': {
        start: {
          line: 13,
          column: 8
        },
        end: {
          line: 13,
          column: 35
        }
      },
      '7': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 18,
          column: 12
        }
      },
      '8': {
        start: {
          line: 17,
          column: 8
        },
        end: {
          line: 17,
          column: 34
        }
      },
      '9': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 24,
          column: 6
        }
      },
      '10': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 35
        }
      },
      '11': {
        start: {
          line: 28,
          column: 0
        },
        end: {
          line: 38,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 2
          },
          end: {
            line: 3,
            column: 3
          }
        },
        loc: {
          start: {
            line: 3,
            column: 27
          },
          end: {
            line: 25,
            column: 3
          }
        },
        line: 3
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 7,
            column: 17
          },
          end: {
            line: 7,
            column: 18
          }
        },
        loc: {
          start: {
            line: 7,
            column: 23
          },
          end: {
            line: 20,
            column: 5
          }
        },
        line: 7
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 16,
            column: 49
          },
          end: {
            line: 16,
            column: 50
          }
        },
        loc: {
          start: {
            line: 16,
            column: 57
          },
          end: {
            line: 18,
            column: 7
          }
        },
        line: 16
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 21
          },
          end: {
            line: 22,
            column: 22
          }
        },
        loc: {
          start: {
            line: 22,
            column: 37
          },
          end: {
            line: 24,
            column: 5
          }
        },
        line: 22
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 8,
            column: 6
          },
          end: {
            line: 10,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 8,
            column: 6
          },
          end: {
            line: 10,
            column: 7
          }
        }, {
          start: {
            line: 8,
            column: 6
          },
          end: {
            line: 10,
            column: 7
          }
        }],
        line: 8
      },
      '1': {
        loc: {
          start: {
            line: 9,
            column: 24
          },
          end: {
            line: 9,
            column: 145
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 9,
            column: 62
          },
          end: {
            line: 9,
            column: 103
          }
        }, {
          start: {
            line: 9,
            column: 106
          },
          end: {
            line: 9,
            column: 145
          }
        }],
        line: 9
      },
      '2': {
        loc: {
          start: {
            line: 12,
            column: 6
          },
          end: {
            line: 14,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 12,
            column: 6
          },
          end: {
            line: 14,
            column: 7
          }
        }, {
          start: {
            line: 12,
            column: 6
          },
          end: {
            line: 14,
            column: 7
          }
        }],
        line: 12
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SmallProductBoxController =
/** @ngInject */
function SmallProductBoxController(env, $state) {
  _classCallCheck(this, SmallProductBoxController);

  cov_ztnzvlc7k.f[0]++;

  var vm = (cov_ztnzvlc7k.s[0]++, this);
  cov_ztnzvlc7k.s[1]++;
  vm.currency = env.currency;

  cov_ztnzvlc7k.s[2]++;
  vm.$onInit = function () {
    cov_ztnzvlc7k.f[1]++;
    cov_ztnzvlc7k.s[3]++;

    if (vm.product.promo !== 0) {
      cov_ztnzvlc7k.b[0][0]++;
      cov_ztnzvlc7k.s[4]++;

      vm.promoLabel = vm.product.promoData.type === 'v' ? (cov_ztnzvlc7k.b[1][0]++, '-' + vm.product.promoData.value + ' lei') : (cov_ztnzvlc7k.b[1][1]++, '-' + vm.product.promoData.value + ' %');
    } else {
      cov_ztnzvlc7k.b[0][1]++;
    }

    cov_ztnzvlc7k.s[5]++;
    if (vm.product.price === null) {
      cov_ztnzvlc7k.b[2][0]++;
      cov_ztnzvlc7k.s[6]++;

      vm.pricePreLabel = 'De la';
    } else {
      cov_ztnzvlc7k.b[2][1]++;
    }

    cov_ztnzvlc7k.s[7]++;
    vm.primaryPhoto = vm.product.images.filter(function (item) {
      cov_ztnzvlc7k.f[2]++;
      cov_ztnzvlc7k.s[8]++;

      return item.primary === 1;
    })[0];
  };

  cov_ztnzvlc7k.s[9]++;
  vm.goToProduct = function (slug) {
    cov_ztnzvlc7k.f[3]++;
    cov_ztnzvlc7k.s[10]++;

    $state.go('product', { slug: slug });
  };
};

cov_ztnzvlc7k.s[11]++;


angular.module('app').component('smallProductBox', {
  templateUrl: 'app/components/small-product-box/small-product-box.html',
  controller: SmallProductBoxController,
  controllerAs: 'vm',
  bindToController: true,
  bindings: {
    product: '='
  }
});