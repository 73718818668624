'use strict';

var cov_1d34u61478 = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/cart/cart.factory.js',
      hash = 'e5194e25d41f6208cf6575bdf0f645bd656c448f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/cart/cart.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 39
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 29,
          column: 4
        }
      },
      '2': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 13,
          column: 9
        }
      },
      '3': {
        start: {
          line: 16,
          column: 6
        },
        end: {
          line: 20,
          column: 9
        }
      },
      '4': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 27,
          column: 9
        }
      }
    },
    fnMap: {
      '0': {
        name: 'CartFactory',
        decl: {
          start: {
            line: 6,
            column: 9
          },
          end: {
            line: 6,
            column: 20
          }
        },
        loc: {
          start: {
            line: 6,
            column: 33
          },
          end: {
            line: 30,
            column: 1
          }
        },
        line: 6
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 8,
            column: 9
          },
          end: {
            line: 8,
            column: 10
          }
        },
        loc: {
          start: {
            line: 8,
            column: 17
          },
          end: {
            line: 14,
            column: 5
          }
        },
        line: 8
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 15,
            column: 12
          },
          end: {
            line: 15,
            column: 13
          }
        },
        loc: {
          start: {
            line: 15,
            column: 20
          },
          end: {
            line: 21,
            column: 5
          }
        },
        line: 15
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 22,
            column: 12
          },
          end: {
            line: 22,
            column: 13
          }
        },
        loc: {
          start: {
            line: 22,
            column: 20
          },
          end: {
            line: 28,
            column: 5
          }
        },
        line: 22
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1d34u61478.s[0]++;
angular.module('app').factory('CartFactory', CartFactory);

/** @ngInject */
function CartFactory(Api, $http) {
  cov_1d34u61478.f[0]++;
  cov_1d34u61478.s[1]++;

  return {
    get: function get(data) {
      cov_1d34u61478.f[1]++;
      cov_1d34u61478.s[2]++;

      return $http({
        url: Api.generate('cart', 'get-one'),
        method: 'POST',
        data: data
      });
    },
    action: function action(data) {
      cov_1d34u61478.f[2]++;
      cov_1d34u61478.s[3]++;

      return $http({
        url: Api.generate('cart', 'action'),
        method: 'POST',
        data: data
      });
    },
    remove: function remove(data) {
      cov_1d34u61478.f[3]++;
      cov_1d34u61478.s[4]++;

      return $http({
        url: Api.generate('cart', 'remove'),
        method: 'POST',
        data: data
      });
    }
  };
}