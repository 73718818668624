'use strict';

var cov_mvb0u1o5n = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/factories/api.factory.js',
      hash = '055d05c51ac4401543f500934d96023132069654',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/factories/api.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 4,
          column: 23
        }
      },
      '1': {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 49
        }
      },
      '2': {
        start: {
          line: 13,
          column: 17
        },
        end: {
          line: 17,
          column: 3
        }
      },
      '3': {
        start: {
          line: 20,
          column: 31
        },
        end: {
          line: 20,
          column: 51
        }
      },
      '4': {
        start: {
          line: 21,
          column: 31
        },
        end: {
          line: 21,
          column: 33
        }
      },
      '5': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 25,
          column: 5
        }
      },
      '6': {
        start: {
          line: 24,
          column: 6
        },
        end: {
          line: 24,
          column: 44
        }
      },
      '7': {
        start: {
          line: 27,
          column: 24
        },
        end: {
          line: 27,
          column: 62
        }
      },
      '8': {
        start: {
          line: 29,
          column: 4
        },
        end: {
          line: 33,
          column: 5
        }
      },
      '9': {
        start: {
          line: 30,
          column: 6
        },
        end: {
          line: 30,
          column: 31
        }
      },
      '10': {
        start: {
          line: 32,
          column: 6
        },
        end: {
          line: 32,
          column: 27
        }
      },
      '11': {
        start: {
          line: 35,
          column: 32
        },
        end: {
          line: 46,
          column: 5
        }
      },
      '12': {
        start: {
          line: 36,
          column: 23
        },
        end: {
          line: 36,
          column: 25
        }
      },
      '13': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 39,
          column: 7
        }
      },
      '14': {
        start: {
          line: 38,
          column: 8
        },
        end: {
          line: 38,
          column: 26
        }
      },
      '15': {
        start: {
          line: 40,
          column: 6
        },
        end: {
          line: 44,
          column: 7
        }
      },
      '16': {
        start: {
          line: 41,
          column: 8
        },
        end: {
          line: 43,
          column: 9
        }
      },
      '17': {
        start: {
          line: 42,
          column: 10
        },
        end: {
          line: 42,
          column: 59
        }
      },
      '18': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 45,
          column: 24
        }
      },
      '19': {
        start: {
          line: 47,
          column: 4
        },
        end: {
          line: 47,
          column: 112
        }
      },
      '20': {
        start: {
          line: 50,
          column: 2
        },
        end: {
          line: 50,
          column: 16
        }
      }
    },
    fnMap: {
      '0': {
        name: 'lsp',
        decl: {
          start: {
            line: 7,
            column: 9
          },
          end: {
            line: 7,
            column: 12
          }
        },
        loc: {
          start: {
            line: 7,
            column: 42
          },
          end: {
            line: 9,
            column: 1
          }
        },
        line: 7
      },
      '1': {
        name: 'Api',
        decl: {
          start: {
            line: 12,
            column: 9
          },
          end: {
            line: 12,
            column: 12
          }
        },
        loc: {
          start: {
            line: 12,
            column: 18
          },
          end: {
            line: 51,
            column: 1
          }
        },
        line: 12
      },
      '2': {
        name: 'generate',
        decl: {
          start: {
            line: 19,
            column: 11
          },
          end: {
            line: 19,
            column: 19
          }
        },
        loc: {
          start: {
            line: 19,
            column: 22
          },
          end: {
            line: 48,
            column: 3
          }
        },
        line: 19
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 35,
            column: 32
          },
          end: {
            line: 35,
            column: 33
          }
        },
        loc: {
          start: {
            line: 35,
            column: 44
          },
          end: {
            line: 46,
            column: 5
          }
        },
        line: 35
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 29,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 29,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        }, {
          start: {
            line: 29,
            column: 4
          },
          end: {
            line: 33,
            column: 5
          }
        }],
        line: 29
      },
      '1': {
        loc: {
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        }, {
          start: {
            line: 37,
            column: 6
          },
          end: {
            line: 39,
            column: 7
          }
        }],
        line: 37
      },
      '2': {
        loc: {
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 43,
            column: 9
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 43,
            column: 9
          }
        }, {
          start: {
            line: 41,
            column: 8
          },
          end: {
            line: 43,
            column: 9
          }
        }],
        line: 41
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_mvb0u1o5n.s[0]++;
angular.module('app').config(lsp).factory('Api', Api);

/** @ngInject */
function lsp(localStorageServiceProvider) {
  cov_mvb0u1o5n.f[0]++;
  cov_mvb0u1o5n.s[1]++;

  localStorageServiceProvider.setPrefix('CSHOP');
}

/** @ngInject */
function Api(env) {
  cov_mvb0u1o5n.f[1]++;

  var config = (cov_mvb0u1o5n.s[2]++, {
    generate: generate,
    apiPrefix: env.apiPrefix,
    apiHost: env.appHost
  });

  function generate() {
    cov_mvb0u1o5n.f[2]++;

    var _argumentsListSize = (cov_mvb0u1o5n.s[3]++, arguments.length - 1);
    var _argumentLineArray = (cov_mvb0u1o5n.s[4]++, []);

    cov_mvb0u1o5n.s[5]++;
    for (var i = 0; i <= _argumentsListSize; i++) {
      cov_mvb0u1o5n.s[6]++;

      _argumentLineArray.push(arguments[i]);
    }

    var _lastArgument = (cov_mvb0u1o5n.s[7]++, _argumentLineArray[_argumentsListSize]);

    cov_mvb0u1o5n.s[8]++;
    if (angular.isObject(_lastArgument)) {
      cov_mvb0u1o5n.b[0][0]++;
      cov_mvb0u1o5n.s[9]++;

      _argumentLineArray.pop();
    } else {
      cov_mvb0u1o5n.b[0][1]++;
      cov_mvb0u1o5n.s[10]++;

      _lastArgument = null;
    }

    cov_mvb0u1o5n.s[11]++;
    var _generateSizeOffset = function _generateSizeOffset() {
      cov_mvb0u1o5n.f[3]++;

      var _definedby = (cov_mvb0u1o5n.s[12]++, '');
      cov_mvb0u1o5n.s[13]++;
      if (!_lastArgument) {
        cov_mvb0u1o5n.b[1][0]++;
        cov_mvb0u1o5n.s[14]++;

        return _definedby;
      } else {
        cov_mvb0u1o5n.b[1][1]++;
      }
      cov_mvb0u1o5n.s[15]++;
      for (var k in _lastArgument) {
        cov_mvb0u1o5n.s[16]++;

        if ({}.hasOwnProperty.call(k, _lastArgument)) {
          cov_mvb0u1o5n.b[2][0]++;
          cov_mvb0u1o5n.s[17]++;

          _definedby += k + '=' + _lastArgument[k] + '&';
        } else {
          cov_mvb0u1o5n.b[2][1]++;
        }
      }
      cov_mvb0u1o5n.s[18]++;
      return _definedby;
    };
    cov_mvb0u1o5n.s[19]++;
    return config.apiHost + '/' + config.apiPrefix + '/' + _argumentLineArray.join('/') + _generateSizeOffset();
  }

  cov_mvb0u1o5n.s[20]++;
  return config;
}