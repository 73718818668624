'use strict';

var cov_1wvc9nf1at = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/category-page/category.factory.js',
      hash = '9af0bbddb822d524befc51abafa9bffb6154e4fa',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/category-page/category.factory.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 47
        }
      },
      '1': {
        start: {
          line: 7,
          column: 2
        },
        end: {
          line: 15,
          column: 4
        }
      },
      '2': {
        start: {
          line: 9,
          column: 6
        },
        end: {
          line: 13,
          column: 9
        }
      }
    },
    fnMap: {
      '0': {
        name: 'CategoryFactory',
        decl: {
          start: {
            line: 6,
            column: 9
          },
          end: {
            line: 6,
            column: 24
          }
        },
        loc: {
          start: {
            line: 6,
            column: 37
          },
          end: {
            line: 16,
            column: 1
          }
        },
        line: 6
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 8,
            column: 9
          },
          end: {
            line: 8,
            column: 10
          }
        },
        loc: {
          start: {
            line: 8,
            column: 17
          },
          end: {
            line: 14,
            column: 5
          }
        },
        line: 8
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    f: {
      '0': 0,
      '1': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1wvc9nf1at.s[0]++;
angular.module('app').factory('CategoryFactory', CategoryFactory);

/** @ngInject */
function CategoryFactory(Api, $http) {
  cov_1wvc9nf1at.f[0]++;
  cov_1wvc9nf1at.s[1]++;

  return {
    one: function one(data) {
      cov_1wvc9nf1at.f[1]++;
      cov_1wvc9nf1at.s[2]++;

      return $http({
        url: Api.generate('categories', 'get-one'),
        method: 'POST',
        data: data
      });
    }
  };
}