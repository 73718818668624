'use strict';

var cov_2cfn6g8n0d = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/index.js',
      hash = '99e24eafafaa183a678afb96102e9f7fd16c20e4',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/index.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 3,
          column: 3
        }
      },
      '1': {
        start: {
          line: 2,
          column: 2
        },
        end: {
          line: 2,
          column: 18
        }
      },
      '2': {
        start: {
          line: 4,
          column: 0
        },
        end: {
          line: 11,
          column: 5
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 1,
            column: 46
          },
          end: {
            line: 1,
            column: 47
          }
        },
        loc: {
          start: {
            line: 1,
            column: 52
          },
          end: {
            line: 3,
            column: 1
          }
        },
        line: 1
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2cfn6g8n0d.s[0]++;
angular.module('underscore', []).factory('_', function () {
  cov_2cfn6g8n0d.f[0]++;
  cov_2cfn6g8n0d.s[1]++;

  return window._;
});
cov_2cfn6g8n0d.s[2]++;
angular.module('app', ['ui.router', 'ui.bootstrap', 'LocalStorageModule', 'ngCookies', 'wipImageZoom']);