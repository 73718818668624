'use strict';

var cov_2btqbk3s5q = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/environment/env.constants.js',
      hash = '823432aec0695517854838a8a64d7848b582a330',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/environment/env.constants.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 10,
          column: 5
        }
      }
    },
    fnMap: {},
    branchMap: {},
    s: {
      '0': 0
    },
    f: {},
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2btqbk3s5q.s[0]++;
angular.module('app').constant('env', {
  appHost: 'https://api.coloristic.ro',
  apiPrefix: 'api',
  uploadPath: 'https://api.coloristic.ro/upload',
  currency: {
    label: 'lei'
  }
});