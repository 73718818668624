"use strict";

var cov_1x18pfp9by = function () {
    var path = "/home/coloristic/shop.coloristic.ro/src/assets/wip-image-zoom/wip-image-zoom.js",
        hash = "3fe138735fdf3a94a56fe4bf6dbf37adeb6d0deb",
        Function = function () {}.constructor,
        global = new Function('return this')(),
        gcv = "__coverage__",
        coverageData = {
        path: "/home/coloristic/shop.coloristic.ro/src/assets/wip-image-zoom/wip-image-zoom.js",
        statementMap: {
            "0": {
                start: {
                    line: 1,
                    column: 0
                },
                end: {
                    line: 662,
                    column: 5
                }
            },
            "1": {
                start: {
                    line: 5,
                    column: 4
                },
                end: {
                    line: 5,
                    column: 44
                }
            },
            "2": {
                start: {
                    line: 6,
                    column: 4
                },
                end: {
                    line: 6,
                    column: 49
                }
            },
            "3": {
                start: {
                    line: 7,
                    column: 4
                },
                end: {
                    line: 16,
                    column: 70
                }
            },
            "4": {
                start: {
                    line: 21,
                    column: 8
                },
                end: {
                    line: 47,
                    column: 10
                }
            },
            "5": {
                start: {
                    line: 49,
                    column: 8
                },
                end: {
                    line: 52,
                    column: 10
                }
            },
            "6": {
                start: {
                    line: 51,
                    column: 12
                },
                end: {
                    line: 51,
                    column: 68
                }
            },
            "7": {
                start: {
                    line: 54,
                    column: 8
                },
                end: {
                    line: 57,
                    column: 10
                }
            },
            "8": {
                start: {
                    line: 56,
                    column: 12
                },
                end: {
                    line: 56,
                    column: 24
                }
            },
            "9": {
                start: {
                    line: 63,
                    column: 8
                },
                end: {
                    line: 578,
                    column: 10
                }
            },
            "10": {
                start: {
                    line: 76,
                    column: 16
                },
                end: {
                    line: 76,
                    column: 34
                }
            },
            "11": {
                start: {
                    line: 77,
                    column: 16
                },
                end: {
                    line: 77,
                    column: 35
                }
            },
            "12": {
                start: {
                    line: 78,
                    column: 16
                },
                end: {
                    line: 78,
                    column: 28
                }
            },
            "13": {
                start: {
                    line: 82,
                    column: 25
                },
                end: {
                    line: 82,
                    column: 29
                }
            },
            "14": {
                start: {
                    line: 85,
                    column: 34
                },
                end: {
                    line: 85,
                    column: 75
                }
            },
            "15": {
                start: {
                    line: 86,
                    column: 36
                },
                end: {
                    line: 86,
                    column: 40
                }
            },
            "16": {
                start: {
                    line: 88,
                    column: 16
                },
                end: {
                    line: 88,
                    column: 27
                }
            },
            "17": {
                start: {
                    line: 89,
                    column: 16
                },
                end: {
                    line: 89,
                    column: 36
                }
            },
            "18": {
                start: {
                    line: 90,
                    column: 16
                },
                end: {
                    line: 90,
                    column: 33
                }
            },
            "19": {
                start: {
                    line: 91,
                    column: 16
                },
                end: {
                    line: 91,
                    column: 36
                }
            },
            "20": {
                start: {
                    line: 92,
                    column: 16
                },
                end: {
                    line: 92,
                    column: 38
                }
            },
            "21": {
                start: {
                    line: 93,
                    column: 16
                },
                end: {
                    line: 93,
                    column: 33
                }
            },
            "22": {
                start: {
                    line: 94,
                    column: 16
                },
                end: {
                    line: 94,
                    column: 34
                }
            },
            "23": {
                start: {
                    line: 95,
                    column: 16
                },
                end: {
                    line: 95,
                    column: 32
                }
            },
            "24": {
                start: {
                    line: 96,
                    column: 16
                },
                end: {
                    line: 96,
                    column: 31
                }
            },
            "25": {
                start: {
                    line: 97,
                    column: 16
                },
                end: {
                    line: 97,
                    column: 38
                }
            },
            "26": {
                start: {
                    line: 98,
                    column: 16
                },
                end: {
                    line: 98,
                    column: 44
                }
            },
            "27": {
                start: {
                    line: 100,
                    column: 16
                },
                end: {
                    line: 100,
                    column: 43
                }
            },
            "28": {
                start: {
                    line: 101,
                    column: 16
                },
                end: {
                    line: 101,
                    column: 43
                }
            },
            "29": {
                start: {
                    line: 102,
                    column: 16
                },
                end: {
                    line: 102,
                    column: 34
                }
            },
            "30": {
                start: {
                    line: 103,
                    column: 16
                },
                end: {
                    line: 103,
                    column: 42
                }
            },
            "31": {
                start: {
                    line: 104,
                    column: 16
                },
                end: {
                    line: 104,
                    column: 35
                }
            },
            "32": {
                start: {
                    line: 105,
                    column: 16
                },
                end: {
                    line: 105,
                    column: 33
                }
            },
            "33": {
                start: {
                    line: 106,
                    column: 16
                },
                end: {
                    line: 106,
                    column: 47
                }
            },
            "34": {
                start: {
                    line: 108,
                    column: 16
                },
                end: {
                    line: 108,
                    column: 31
                }
            },
            "35": {
                start: {
                    line: 109,
                    column: 16
                },
                end: {
                    line: 109,
                    column: 39
                }
            },
            "36": {
                start: {
                    line: 110,
                    column: 16
                },
                end: {
                    line: 110,
                    column: 43
                }
            },
            "37": {
                start: {
                    line: 111,
                    column: 16
                },
                end: {
                    line: 111,
                    column: 55
                }
            },
            "38": {
                start: {
                    line: 113,
                    column: 16
                },
                end: {
                    line: 113,
                    column: 53
                }
            },
            "39": {
                start: {
                    line: 114,
                    column: 16
                },
                end: {
                    line: 114,
                    column: 41
                }
            },
            "40": {
                start: {
                    line: 115,
                    column: 16
                },
                end: {
                    line: 115,
                    column: 41
                }
            },
            "41": {
                start: {
                    line: 116,
                    column: 16
                },
                end: {
                    line: 116,
                    column: 63
                }
            },
            "42": {
                start: {
                    line: 120,
                    column: 20
                },
                end: {
                    line: 120,
                    column: 119
                }
            },
            "43": {
                start: {
                    line: 122,
                    column: 20
                },
                end: {
                    line: 122,
                    column: 32
                }
            },
            "44": {
                start: {
                    line: 124,
                    column: 20
                },
                end: {
                    line: 124,
                    column: 67
                }
            },
            "45": {
                start: {
                    line: 125,
                    column: 20
                },
                end: {
                    line: 125,
                    column: 56
                }
            },
            "46": {
                start: {
                    line: 127,
                    column: 20
                },
                end: {
                    line: 127,
                    column: 39
                }
            },
            "47": {
                start: {
                    line: 132,
                    column: 20
                },
                end: {
                    line: 141,
                    column: 21
                }
            },
            "48": {
                start: {
                    line: 134,
                    column: 24
                },
                end: {
                    line: 140,
                    column: 26
                }
            },
            "49": {
                start: {
                    line: 143,
                    column: 20
                },
                end: {
                    line: 143,
                    column: 50
                }
            },
            "50": {
                start: {
                    line: 144,
                    column: 20
                },
                end: {
                    line: 144,
                    column: 70
                }
            },
            "51": {
                start: {
                    line: 150,
                    column: 20
                },
                end: {
                    line: 153,
                    column: 21
                }
            },
            "52": {
                start: {
                    line: 152,
                    column: 24
                },
                end: {
                    line: 152,
                    column: 55
                }
            },
            "53": {
                start: {
                    line: 155,
                    column: 20
                },
                end: {
                    line: 160,
                    column: 28
                }
            },
            "54": {
                start: {
                    line: 157,
                    column: 24
                },
                end: {
                    line: 157,
                    column: 37
                }
            },
            "55": {
                start: {
                    line: 158,
                    column: 24
                },
                end: {
                    line: 158,
                    column: 35
                }
            },
            "56": {
                start: {
                    line: 159,
                    column: 24
                },
                end: {
                    line: 159,
                    column: 42
                }
            },
            "57": {
                start: {
                    line: 165,
                    column: 20
                },
                end: {
                    line: 168,
                    column: 21
                }
            },
            "58": {
                start: {
                    line: 167,
                    column: 24
                },
                end: {
                    line: 167,
                    column: 31
                }
            },
            "59": {
                start: {
                    line: 170,
                    column: 20
                },
                end: {
                    line: 170,
                    column: 32
                }
            },
            "60": {
                start: {
                    line: 172,
                    column: 20
                },
                end: {
                    line: 172,
                    column: 68
                }
            },
            "61": {
                start: {
                    line: 174,
                    column: 20
                },
                end: {
                    line: 181,
                    column: 21
                }
            },
            "62": {
                start: {
                    line: 176,
                    column: 24
                },
                end: {
                    line: 176,
                    column: 60
                }
            },
            "63": {
                start: {
                    line: 180,
                    column: 24
                },
                end: {
                    line: 180,
                    column: 59
                }
            },
            "64": {
                start: {
                    line: 184,
                    column: 20
                },
                end: {
                    line: 184,
                    column: 43
                }
            },
            "65": {
                start: {
                    line: 186,
                    column: 20
                },
                end: {
                    line: 186,
                    column: 160
                }
            },
            "66": {
                start: {
                    line: 188,
                    column: 20
                },
                end: {
                    line: 191,
                    column: 21
                }
            },
            "67": {
                start: {
                    line: 190,
                    column: 24
                },
                end: {
                    line: 190,
                    column: 90
                }
            },
            "68": {
                start: {
                    line: 193,
                    column: 20
                },
                end: {
                    line: 196,
                    column: 21
                }
            },
            "69": {
                start: {
                    line: 195,
                    column: 24
                },
                end: {
                    line: 195,
                    column: 44
                }
            },
            "70": {
                start: {
                    line: 202,
                    column: 20
                },
                end: {
                    line: 202,
                    column: 82
                }
            },
            "71": {
                start: {
                    line: 203,
                    column: 20
                },
                end: {
                    line: 203,
                    column: 83
                }
            },
            "72": {
                start: {
                    line: 205,
                    column: 20
                },
                end: {
                    line: 205,
                    column: 84
                }
            },
            "73": {
                start: {
                    line: 206,
                    column: 20
                },
                end: {
                    line: 206,
                    column: 82
                }
            },
            "74": {
                start: {
                    line: 208,
                    column: 20
                },
                end: {
                    line: 208,
                    column: 87
                }
            },
            "75": {
                start: {
                    line: 209,
                    column: 20
                },
                end: {
                    line: 209,
                    column: 87
                }
            },
            "76": {
                start: {
                    line: 214,
                    column: 20
                },
                end: {
                    line: 214,
                    column: 89
                }
            },
            "77": {
                start: {
                    line: 216,
                    column: 20
                },
                end: {
                    line: 216,
                    column: 85
                }
            },
            "78": {
                start: {
                    line: 217,
                    column: 20
                },
                end: {
                    line: 217,
                    column: 86
                }
            },
            "79": {
                start: {
                    line: 219,
                    column: 20
                },
                end: {
                    line: 219,
                    column: 87
                }
            },
            "80": {
                start: {
                    line: 220,
                    column: 20
                },
                end: {
                    line: 220,
                    column: 85
                }
            },
            "81": {
                start: {
                    line: 222,
                    column: 20
                },
                end: {
                    line: 222,
                    column: 90
                }
            },
            "82": {
                start: {
                    line: 223,
                    column: 20
                },
                end: {
                    line: 223,
                    column: 90
                }
            },
            "83": {
                start: {
                    line: 228,
                    column: 20
                },
                end: {
                    line: 228,
                    column: 96
                }
            },
            "84": {
                start: {
                    line: 229,
                    column: 20
                },
                end: {
                    line: 229,
                    column: 43
                }
            },
            "85": {
                start: {
                    line: 230,
                    column: 20
                },
                end: {
                    line: 230,
                    column: 43
                }
            },
            "86": {
                start: {
                    line: 231,
                    column: 20
                },
                end: {
                    line: 231,
                    column: 29
                }
            },
            "87": {
                start: {
                    line: 236,
                    column: 20
                },
                end: {
                    line: 236,
                    column: 40
                }
            },
            "88": {
                start: {
                    line: 237,
                    column: 20
                },
                end: {
                    line: 237,
                    column: 41
                }
            },
            "89": {
                start: {
                    line: 238,
                    column: 20
                },
                end: {
                    line: 248,
                    column: 23
                }
            },
            "90": {
                start: {
                    line: 240,
                    column: 24
                },
                end: {
                    line: 240,
                    column: 97
                }
            },
            "91": {
                start: {
                    line: 241,
                    column: 35
                },
                end: {
                    line: 241,
                    column: 63
                }
            },
            "92": {
                start: {
                    line: 242,
                    column: 24
                },
                end: {
                    line: 242,
                    column: 120
                }
            },
            "93": {
                start: {
                    line: 243,
                    column: 24
                },
                end: {
                    line: 243,
                    column: 80
                }
            },
            "94": {
                start: {
                    line: 244,
                    column: 24
                },
                end: {
                    line: 244,
                    column: 60
                }
            },
            "95": {
                start: {
                    line: 245,
                    column: 24
                },
                end: {
                    line: 245,
                    column: 312
                }
            },
            "96": {
                start: {
                    line: 246,
                    column: 24
                },
                end: {
                    line: 246,
                    column: 51
                }
            },
            "97": {
                start: {
                    line: 247,
                    column: 24
                },
                end: {
                    line: 247,
                    column: 33
                }
            },
            "98": {
                start: {
                    line: 253,
                    column: 20
                },
                end: {
                    line: 256,
                    column: 21
                }
            },
            "99": {
                start: {
                    line: 255,
                    column: 24
                },
                end: {
                    line: 255,
                    column: 31
                }
            },
            "100": {
                start: {
                    line: 258,
                    column: 20
                },
                end: {
                    line: 258,
                    column: 73
                }
            },
            "101": {
                start: {
                    line: 259,
                    column: 20
                },
                end: {
                    line: 259,
                    column: 123
                }
            },
            "102": {
                start: {
                    line: 260,
                    column: 20
                },
                end: {
                    line: 260,
                    column: 70
                }
            },
            "103": {
                start: {
                    line: 261,
                    column: 20
                },
                end: {
                    line: 261,
                    column: 72
                }
            },
            "104": {
                start: {
                    line: 264,
                    column: 20
                },
                end: {
                    line: 283,
                    column: 23
                }
            },
            "105": {
                start: {
                    line: 266,
                    column: 24
                },
                end: {
                    line: 275,
                    column: 25
                }
            },
            "106": {
                start: {
                    line: 268,
                    column: 28
                },
                end: {
                    line: 268,
                    column: 96
                }
            },
            "107": {
                start: {
                    line: 269,
                    column: 28
                },
                end: {
                    line: 269,
                    column: 61
                }
            },
            "108": {
                start: {
                    line: 273,
                    column: 28
                },
                end: {
                    line: 273,
                    column: 93
                }
            },
            "109": {
                start: {
                    line: 274,
                    column: 28
                },
                end: {
                    line: 274,
                    column: 64
                }
            },
            "110": {
                start: {
                    line: 277,
                    column: 24
                },
                end: {
                    line: 282,
                    column: 25
                }
            },
            "111": {
                start: {
                    line: 279,
                    column: 40
                },
                end: {
                    line: 279,
                    column: 63
                }
            },
            "112": {
                start: {
                    line: 280,
                    column: 28
                },
                end: {
                    line: 280,
                    column: 69
                }
            },
            "113": {
                start: {
                    line: 281,
                    column: 28
                },
                end: {
                    line: 281,
                    column: 89
                }
            },
            "114": {
                start: {
                    line: 288,
                    column: 20
                },
                end: {
                    line: 288,
                    column: 51
                }
            },
            "115": {
                start: {
                    line: 293,
                    column: 20
                },
                end: {
                    line: 293,
                    column: 51
                }
            },
            "116": {
                start: {
                    line: 298,
                    column: 20
                },
                end: {
                    line: 298,
                    column: 56
                }
            },
            "117": {
                start: {
                    line: 299,
                    column: 20
                },
                end: {
                    line: 299,
                    column: 74
                }
            },
            "118": {
                start: {
                    line: 300,
                    column: 20
                },
                end: {
                    line: 300,
                    column: 43
                }
            },
            "119": {
                start: {
                    line: 301,
                    column: 34
                },
                end: {
                    line: 301,
                    column: 67
                }
            },
            "120": {
                start: {
                    line: 302,
                    column: 20
                },
                end: {
                    line: 302,
                    column: 91
                }
            },
            "121": {
                start: {
                    line: 307,
                    column: 37
                },
                end: {
                    line: 307,
                    column: 75
                }
            },
            "122": {
                start: {
                    line: 308,
                    column: 20
                },
                end: {
                    line: 308,
                    column: 48
                }
            },
            "123": {
                start: {
                    line: 309,
                    column: 20
                },
                end: {
                    line: 309,
                    column: 49
                }
            },
            "124": {
                start: {
                    line: 311,
                    column: 20
                },
                end: {
                    line: 325,
                    column: 21
                }
            },
            "125": {
                start: {
                    line: 313,
                    column: 24
                },
                end: {
                    line: 313,
                    column: 48
                }
            },
            "126": {
                start: {
                    line: 314,
                    column: 24
                },
                end: {
                    line: 314,
                    column: 48
                }
            },
            "127": {
                start: {
                    line: 315,
                    column: 24
                },
                end: {
                    line: 315,
                    column: 65
                }
            },
            "128": {
                start: {
                    line: 316,
                    column: 24
                },
                end: {
                    line: 316,
                    column: 66
                }
            },
            "129": {
                start: {
                    line: 321,
                    column: 24
                },
                end: {
                    line: 321,
                    column: 41
                }
            },
            "130": {
                start: {
                    line: 322,
                    column: 24
                },
                end: {
                    line: 322,
                    column: 41
                }
            },
            "131": {
                start: {
                    line: 323,
                    column: 24
                },
                end: {
                    line: 323,
                    column: 59
                }
            },
            "132": {
                start: {
                    line: 324,
                    column: 24
                },
                end: {
                    line: 324,
                    column: 60
                }
            },
            "133": {
                start: {
                    line: 327,
                    column: 20
                },
                end: {
                    line: 327,
                    column: 52
                }
            },
            "134": {
                start: {
                    line: 328,
                    column: 20
                },
                end: {
                    line: 328,
                    column: 53
                }
            },
            "135": {
                start: {
                    line: 330,
                    column: 20
                },
                end: {
                    line: 352,
                    column: 21
                }
            },
            "136": {
                start: {
                    line: 332,
                    column: 24
                },
                end: {
                    line: 332,
                    column: 92
                }
            },
            "137": {
                start: {
                    line: 333,
                    column: 24
                },
                end: {
                    line: 333,
                    column: 93
                }
            },
            "138": {
                start: {
                    line: 337,
                    column: 24
                },
                end: {
                    line: 351,
                    column: 25
                }
            },
            "139": {
                start: {
                    line: 340,
                    column: 28
                },
                end: {
                    line: 350,
                    column: 29
                }
            },
            "140": {
                start: {
                    line: 342,
                    column: 32
                },
                end: {
                    line: 342,
                    column: 81
                }
            },
            "141": {
                start: {
                    line: 343,
                    column: 32
                },
                end: {
                    line: 343,
                    column: 64
                }
            },
            "142": {
                start: {
                    line: 348,
                    column: 32
                },
                end: {
                    line: 348,
                    column: 80
                }
            },
            "143": {
                start: {
                    line: 349,
                    column: 32
                },
                end: {
                    line: 349,
                    column: 65
                }
            },
            "144": {
                start: {
                    line: 354,
                    column: 20
                },
                end: {
                    line: 354,
                    column: 58
                }
            },
            "145": {
                start: {
                    line: 355,
                    column: 20
                },
                end: {
                    line: 355,
                    column: 59
                }
            },
            "146": {
                start: {
                    line: 356,
                    column: 20
                },
                end: {
                    line: 356,
                    column: 34
                }
            },
            "147": {
                start: {
                    line: 362,
                    column: 20
                },
                end: {
                    line: 362,
                    column: 39
                }
            },
            "148": {
                start: {
                    line: 363,
                    column: 29
                },
                end: {
                    line: 363,
                    column: 80
                }
            },
            "149": {
                start: {
                    line: 365,
                    column: 20
                },
                end: {
                    line: 365,
                    column: 55
                }
            },
            "150": {
                start: {
                    line: 366,
                    column: 20
                },
                end: {
                    line: 366,
                    column: 55
                }
            },
            "151": {
                start: {
                    line: 368,
                    column: 20
                },
                end: {
                    line: 368,
                    column: 38
                }
            },
            "152": {
                start: {
                    line: 370,
                    column: 20
                },
                end: {
                    line: 378,
                    column: 21
                }
            },
            "153": {
                start: {
                    line: 372,
                    column: 24
                },
                end: {
                    line: 372,
                    column: 36
                }
            },
            "154": {
                start: {
                    line: 377,
                    column: 24
                },
                end: {
                    line: 377,
                    column: 39
                }
            },
            "155": {
                start: {
                    line: 384,
                    column: 31
                },
                end: {
                    line: 384,
                    column: 106
                }
            },
            "156": {
                start: {
                    line: 385,
                    column: 31
                },
                end: {
                    line: 385,
                    column: 104
                }
            },
            "157": {
                start: {
                    line: 386,
                    column: 20
                },
                end: {
                    line: 386,
                    column: 110
                }
            },
            "158": {
                start: {
                    line: 391,
                    column: 31
                },
                end: {
                    line: 391,
                    column: 86
                }
            },
            "159": {
                start: {
                    line: 392,
                    column: 31
                },
                end: {
                    line: 392,
                    column: 86
                }
            },
            "160": {
                start: {
                    line: 395,
                    column: 20
                },
                end: {
                    line: 395,
                    column: 56
                }
            },
            "161": {
                start: {
                    line: 396,
                    column: 20
                },
                end: {
                    line: 396,
                    column: 56
                }
            },
            "162": {
                start: {
                    line: 397,
                    column: 20
                },
                end: {
                    line: 397,
                    column: 84
                }
            },
            "163": {
                start: {
                    line: 398,
                    column: 20
                },
                end: {
                    line: 398,
                    column: 84
                }
            },
            "164": {
                start: {
                    line: 400,
                    column: 20
                },
                end: {
                    line: 400,
                    column: 110
                }
            },
            "165": {
                start: {
                    line: 405,
                    column: 20
                },
                end: {
                    line: 405,
                    column: 57
                }
            },
            "166": {
                start: {
                    line: 406,
                    column: 20
                },
                end: {
                    line: 406,
                    column: 51
                }
            },
            "167": {
                start: {
                    line: 407,
                    column: 20
                },
                end: {
                    line: 407,
                    column: 51
                }
            },
            "168": {
                start: {
                    line: 408,
                    column: 20
                },
                end: {
                    line: 408,
                    column: 59
                }
            },
            "169": {
                start: {
                    line: 409,
                    column: 20
                },
                end: {
                    line: 409,
                    column: 60
                }
            },
            "170": {
                start: {
                    line: 416,
                    column: 24
                },
                end: {
                    line: 416,
                    column: 95
                }
            },
            "171": {
                start: {
                    line: 421,
                    column: 24
                },
                end: {
                    line: 421,
                    column: 65
                }
            },
            "172": {
                start: {
                    line: 424,
                    column: 30
                },
                end: {
                    line: 424,
                    column: 76
                }
            },
            "173": {
                start: {
                    line: 425,
                    column: 30
                },
                end: {
                    line: 425,
                    column: 44
                }
            },
            "174": {
                start: {
                    line: 426,
                    column: 34
                },
                end: {
                    line: 426,
                    column: 53
                }
            },
            "175": {
                start: {
                    line: 428,
                    column: 37
                },
                end: {
                    line: 428,
                    column: 75
                }
            },
            "176": {
                start: {
                    line: 429,
                    column: 35
                },
                end: {
                    line: 429,
                    column: 87
                }
            },
            "177": {
                start: {
                    line: 430,
                    column: 35
                },
                end: {
                    line: 430,
                    column: 89
                }
            },
            "178": {
                start: {
                    line: 432,
                    column: 20
                },
                end: {
                    line: 432,
                    column: 65
                }
            },
            "179": {
                start: {
                    line: 433,
                    column: 20
                },
                end: {
                    line: 433,
                    column: 65
                }
            },
            "180": {
                start: {
                    line: 435,
                    column: 20
                },
                end: {
                    line: 435,
                    column: 91
                }
            },
            "181": {
                start: {
                    line: 436,
                    column: 20
                },
                end: {
                    line: 436,
                    column: 59
                }
            },
            "182": {
                start: {
                    line: 438,
                    column: 20
                },
                end: {
                    line: 438,
                    column: 91
                }
            },
            "183": {
                start: {
                    line: 439,
                    column: 20
                },
                end: {
                    line: 439,
                    column: 59
                }
            },
            "184": {
                start: {
                    line: 441,
                    column: 20
                },
                end: {
                    line: 441,
                    column: 105
                }
            },
            "185": {
                start: {
                    line: 446,
                    column: 20
                },
                end: {
                    line: 449,
                    column: 21
                }
            },
            "186": {
                start: {
                    line: 448,
                    column: 24
                },
                end: {
                    line: 448,
                    column: 31
                }
            },
            "187": {
                start: {
                    line: 451,
                    column: 40
                },
                end: {
                    line: 451,
                    column: 58
                }
            },
            "188": {
                start: {
                    line: 452,
                    column: 35
                },
                end: {
                    line: 452,
                    column: 117
                }
            },
            "189": {
                start: {
                    line: 453,
                    column: 20
                },
                end: {
                    line: 457,
                    column: 21
                }
            },
            "190": {
                start: {
                    line: 455,
                    column: 24
                },
                end: {
                    line: 455,
                    column: 51
                }
            },
            "191": {
                start: {
                    line: 456,
                    column: 24
                },
                end: {
                    line: 456,
                    column: 31
                }
            },
            "192": {
                start: {
                    line: 458,
                    column: 20
                },
                end: {
                    line: 458,
                    column: 48
                }
            },
            "193": {
                start: {
                    line: 463,
                    column: 20
                },
                end: {
                    line: 469,
                    column: 21
                }
            },
            "194": {
                start: {
                    line: 465,
                    column: 24
                },
                end: {
                    line: 468,
                    column: 25
                }
            },
            "195": {
                start: {
                    line: 467,
                    column: 28
                },
                end: {
                    line: 467,
                    column: 37
                }
            },
            "196": {
                start: {
                    line: 474,
                    column: 20
                },
                end: {
                    line: 477,
                    column: 23
                }
            },
            "197": {
                start: {
                    line: 476,
                    column: 24
                },
                end: {
                    line: 476,
                    column: 45
                }
            },
            "198": {
                start: {
                    line: 482,
                    column: 20
                },
                end: {
                    line: 485,
                    column: 23
                }
            },
            "199": {
                start: {
                    line: 484,
                    column: 24
                },
                end: {
                    line: 484,
                    column: 46
                }
            },
            "200": {
                start: {
                    line: 491,
                    column: 20
                },
                end: {
                    line: 495,
                    column: 21
                }
            },
            "201": {
                start: {
                    line: 493,
                    column: 24
                },
                end: {
                    line: 493,
                    column: 46
                }
            },
            "202": {
                start: {
                    line: 494,
                    column: 24
                },
                end: {
                    line: 494,
                    column: 42
                }
            },
            "203": {
                start: {
                    line: 501,
                    column: 20
                },
                end: {
                    line: 505,
                    column: 21
                }
            },
            "204": {
                start: {
                    line: 503,
                    column: 24
                },
                end: {
                    line: 503,
                    column: 57
                }
            },
            "205": {
                start: {
                    line: 504,
                    column: 24
                },
                end: {
                    line: 504,
                    column: 42
                }
            },
            "206": {
                start: {
                    line: 510,
                    column: 20
                },
                end: {
                    line: 510,
                    column: 38
                }
            },
            "207": {
                start: {
                    line: 511,
                    column: 20
                },
                end: {
                    line: 511,
                    column: 48
                }
            },
            "208": {
                start: {
                    line: 512,
                    column: 20
                },
                end: {
                    line: 512,
                    column: 41
                }
            },
            "209": {
                start: {
                    line: 515,
                    column: 20
                },
                end: {
                    line: 515,
                    column: 41
                }
            },
            "210": {
                start: {
                    line: 517,
                    column: 20
                },
                end: {
                    line: 517,
                    column: 70
                }
            },
            "211": {
                start: {
                    line: 518,
                    column: 20
                },
                end: {
                    line: 518,
                    column: 75
                }
            },
            "212": {
                start: {
                    line: 521,
                    column: 20
                },
                end: {
                    line: 521,
                    column: 39
                }
            },
            "213": {
                start: {
                    line: 526,
                    column: 20
                },
                end: {
                    line: 526,
                    column: 100
                }
            },
            "214": {
                start: {
                    line: 527,
                    column: 20
                },
                end: {
                    line: 527,
                    column: 100
                }
            },
            "215": {
                start: {
                    line: 532,
                    column: 20
                },
                end: {
                    line: 532,
                    column: 43
                }
            },
            "216": {
                start: {
                    line: 533,
                    column: 20
                },
                end: {
                    line: 533,
                    column: 43
                }
            },
            "217": {
                start: {
                    line: 538,
                    column: 20
                },
                end: {
                    line: 538,
                    column: 49
                }
            },
            "218": {
                start: {
                    line: 539,
                    column: 20
                },
                end: {
                    line: 539,
                    column: 32
                }
            },
            "219": {
                start: {
                    line: 542,
                    column: 16
                },
                end: {
                    line: 545,
                    column: 19
                }
            },
            "220": {
                start: {
                    line: 544,
                    column: 20
                },
                end: {
                    line: 544,
                    column: 29
                }
            },
            "221": {
                start: {
                    line: 547,
                    column: 16
                },
                end: {
                    line: 553,
                    column: 17
                }
            },
            "222": {
                start: {
                    line: 549,
                    column: 20
                },
                end: {
                    line: 552,
                    column: 23
                }
            },
            "223": {
                start: {
                    line: 551,
                    column: 24
                },
                end: {
                    line: 551,
                    column: 36
                }
            },
            "224": {
                start: {
                    line: 555,
                    column: 16
                },
                end: {
                    line: 567,
                    column: 25
                }
            },
            "225": {
                start: {
                    line: 557,
                    column: 20
                },
                end: {
                    line: 560,
                    column: 22
                }
            },
            "226": {
                start: {
                    line: 563,
                    column: 20
                },
                end: {
                    line: 566,
                    column: 21
                }
            },
            "227": {
                start: {
                    line: 565,
                    column: 24
                },
                end: {
                    line: 565,
                    column: 33
                }
            },
            "228": {
                start: {
                    line: 569,
                    column: 16
                },
                end: {
                    line: 576,
                    column: 25
                }
            },
            "229": {
                start: {
                    line: 571,
                    column: 20
                },
                end: {
                    line: 575,
                    column: 21
                }
            },
            "230": {
                start: {
                    line: 573,
                    column: 24
                },
                end: {
                    line: 573,
                    column: 31
                }
            },
            "231": {
                start: {
                    line: 574,
                    column: 24
                },
                end: {
                    line: 574,
                    column: 33
                }
            },
            "232": {
                start: {
                    line: 583,
                    column: 8
                },
                end: {
                    line: 590,
                    column: 10
                }
            },
            "233": {
                start: {
                    line: 588,
                    column: 16
                },
                end: {
                    line: 588,
                    column: 43
                }
            },
            "234": {
                start: {
                    line: 595,
                    column: 8
                },
                end: {
                    line: 602,
                    column: 10
                }
            },
            "235": {
                start: {
                    line: 600,
                    column: 16
                },
                end: {
                    line: 600,
                    column: 46
                }
            },
            "236": {
                start: {
                    line: 607,
                    column: 8
                },
                end: {
                    line: 614,
                    column: 10
                }
            },
            "237": {
                start: {
                    line: 612,
                    column: 16
                },
                end: {
                    line: 612,
                    column: 45
                }
            },
            "238": {
                start: {
                    line: 619,
                    column: 8
                },
                end: {
                    line: 626,
                    column: 10
                }
            },
            "239": {
                start: {
                    line: 624,
                    column: 16
                },
                end: {
                    line: 624,
                    column: 46
                }
            },
            "240": {
                start: {
                    line: 631,
                    column: 8
                },
                end: {
                    line: 641,
                    column: 10
                }
            },
            "241": {
                start: {
                    line: 637,
                    column: 16
                },
                end: {
                    line: 637,
                    column: 92
                }
            },
            "242": {
                start: {
                    line: 638,
                    column: 16
                },
                end: {
                    line: 638,
                    column: 79
                }
            },
            "243": {
                start: {
                    line: 639,
                    column: 16
                },
                end: {
                    line: 639,
                    column: 34
                }
            },
            "244": {
                start: {
                    line: 646,
                    column: 8
                },
                end: {
                    line: 659,
                    column: 10
                }
            },
            "245": {
                start: {
                    line: 650,
                    column: 16
                },
                end: {
                    line: 653,
                    column: 26
                }
            },
            "246": {
                start: {
                    line: 652,
                    column: 20
                },
                end: {
                    line: 652,
                    column: 52
                }
            },
            "247": {
                start: {
                    line: 654,
                    column: 16
                },
                end: {
                    line: 657,
                    column: 19
                }
            },
            "248": {
                start: {
                    line: 656,
                    column: 20
                },
                end: {
                    line: 656,
                    column: 59
                }
            }
        },
        fnMap: {
            "0": {
                name: "(anonymous_0)",
                decl: {
                    start: {
                        line: 1,
                        column: 1
                    },
                    end: {
                        line: 1,
                        column: 2
                    }
                },
                loc: {
                    start: {
                        line: 2,
                        column: 0
                    },
                    end: {
                        line: 662,
                        column: 1
                    }
                },
                line: 2
            },
            "1": {
                name: "wipImageZoomConfig",
                decl: {
                    start: {
                        line: 19,
                        column: 13
                    },
                    end: {
                        line: 19,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 20,
                        column: 4
                    },
                    end: {
                        line: 59,
                        column: 5
                    }
                },
                line: 20
            },
            "2": {
                name: "(anonymous_2)",
                decl: {
                    start: {
                        line: 49,
                        column: 27
                    },
                    end: {
                        line: 49,
                        column: 28
                    }
                },
                loc: {
                    start: {
                        line: 50,
                        column: 8
                    },
                    end: {
                        line: 52,
                        column: 9
                    }
                },
                line: 50
            },
            "3": {
                name: "(anonymous_3)",
                decl: {
                    start: {
                        line: 54,
                        column: 20
                    },
                    end: {
                        line: 54,
                        column: 21
                    }
                },
                loc: {
                    start: {
                        line: 55,
                        column: 8
                    },
                    end: {
                        line: 57,
                        column: 9
                    }
                },
                line: 55
            },
            "4": {
                name: "wipImageZoomDirective",
                decl: {
                    start: {
                        line: 61,
                        column: 13
                    },
                    end: {
                        line: 61,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 62,
                        column: 4
                    },
                    end: {
                        line: 579,
                        column: 5
                    }
                },
                line: 62
            },
            "5": {
                name: "(anonymous_5)",
                decl: {
                    start: {
                        line: 74,
                        column: 26
                    },
                    end: {
                        line: 74,
                        column: 27
                    }
                },
                loc: {
                    start: {
                        line: 75,
                        column: 12
                    },
                    end: {
                        line: 79,
                        column: 13
                    }
                },
                line: 75
            },
            "6": {
                name: "(anonymous_6)",
                decl: {
                    start: {
                        line: 80,
                        column: 95
                    },
                    end: {
                        line: 80,
                        column: 96
                    }
                },
                loc: {
                    start: {
                        line: 81,
                        column: 12
                    },
                    end: {
                        line: 577,
                        column: 13
                    }
                },
                line: 81
            },
            "7": {
                name: "init",
                decl: {
                    start: {
                        line: 118,
                        column: 25
                    },
                    end: {
                        line: 118,
                        column: 29
                    }
                },
                loc: {
                    start: {
                        line: 119,
                        column: 16
                    },
                    end: {
                        line: 128,
                        column: 17
                    }
                },
                line: 119
            },
            "8": {
                name: "setImages",
                decl: {
                    start: {
                        line: 130,
                        column: 25
                    },
                    end: {
                        line: 130,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 131,
                        column: 16
                    },
                    end: {
                        line: 145,
                        column: 17
                    }
                },
                line: 131
            },
            "9": {
                name: "update",
                decl: {
                    start: {
                        line: 147,
                        column: 25
                    },
                    end: {
                        line: 147,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 148,
                        column: 16
                    },
                    end: {
                        line: 161,
                        column: 17
                    }
                },
                line: 148
            },
            "10": {
                name: "(anonymous_10)",
                decl: {
                    start: {
                        line: 155,
                        column: 45
                    },
                    end: {
                        line: 155,
                        column: 46
                    }
                },
                loc: {
                    start: {
                        line: 156,
                        column: 20
                    },
                    end: {
                        line: 160,
                        column: 21
                    }
                },
                line: 156
            },
            "11": {
                name: "initZoom",
                decl: {
                    start: {
                        line: 163,
                        column: 25
                    },
                    end: {
                        line: 163,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 164,
                        column: 16
                    },
                    end: {
                        line: 198,
                        column: 17
                    }
                },
                line: 164
            },
            "12": {
                name: "addEventListeners",
                decl: {
                    start: {
                        line: 200,
                        column: 25
                    },
                    end: {
                        line: 200,
                        column: 42
                    }
                },
                loc: {
                    start: {
                        line: 201,
                        column: 16
                    },
                    end: {
                        line: 210,
                        column: 17
                    }
                },
                line: 201
            },
            "13": {
                name: "removeEventListeners",
                decl: {
                    start: {
                        line: 212,
                        column: 25
                    },
                    end: {
                        line: 212,
                        column: 45
                    }
                },
                loc: {
                    start: {
                        line: 213,
                        column: 16
                    },
                    end: {
                        line: 224,
                        column: 17
                    }
                },
                line: 213
            },
            "14": {
                name: "disableImmersiveMode",
                decl: {
                    start: {
                        line: 226,
                        column: 25
                    },
                    end: {
                        line: 226,
                        column: 45
                    }
                },
                loc: {
                    start: {
                        line: 227,
                        column: 16
                    },
                    end: {
                        line: 232,
                        column: 17
                    }
                },
                line: 227
            },
            "15": {
                name: "enableImmersiveMode",
                decl: {
                    start: {
                        line: 234,
                        column: 25
                    },
                    end: {
                        line: 234,
                        column: 44
                    }
                },
                loc: {
                    start: {
                        line: 235,
                        column: 16
                    },
                    end: {
                        line: 249,
                        column: 17
                    }
                },
                line: 235
            },
            "16": {
                name: "(anonymous_16)",
                decl: {
                    start: {
                        line: 238,
                        column: 34
                    },
                    end: {
                        line: 238,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 239,
                        column: 20
                    },
                    end: {
                        line: 248,
                        column: 21
                    }
                },
                line: 239
            },
            "17": {
                name: "initThumbs",
                decl: {
                    start: {
                        line: 251,
                        column: 25
                    },
                    end: {
                        line: 251,
                        column: 35
                    }
                },
                loc: {
                    start: {
                        line: 252,
                        column: 16
                    },
                    end: {
                        line: 284,
                        column: 17
                    }
                },
                line: 252
            },
            "18": {
                name: "(anonymous_18)",
                decl: {
                    start: {
                        line: 264,
                        column: 38
                    },
                    end: {
                        line: 264,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 265,
                        column: 20
                    },
                    end: {
                        line: 283,
                        column: 21
                    }
                },
                line: 265
            },
            "19": {
                name: "nextThumb",
                decl: {
                    start: {
                        line: 286,
                        column: 25
                    },
                    end: {
                        line: 286,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 287,
                        column: 16
                    },
                    end: {
                        line: 289,
                        column: 17
                    }
                },
                line: 287
            },
            "20": {
                name: "prevThumb",
                decl: {
                    start: {
                        line: 291,
                        column: 25
                    },
                    end: {
                        line: 291,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 292,
                        column: 16
                    },
                    end: {
                        line: 294,
                        column: 17
                    }
                },
                line: 292
            },
            "21": {
                name: "scrollThumbs",
                decl: {
                    start: {
                        line: 296,
                        column: 25
                    },
                    end: {
                        line: 296,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 297,
                        column: 16
                    },
                    end: {
                        line: 303,
                        column: 17
                    }
                },
                line: 297
            },
            "22": {
                name: "initSizes",
                decl: {
                    start: {
                        line: 305,
                        column: 25
                    },
                    end: {
                        line: 305,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 306,
                        column: 16
                    },
                    end: {
                        line: 358,
                        column: 17
                    }
                },
                line: 306
            },
            "23": {
                name: "setZoomImagePosition",
                decl: {
                    start: {
                        line: 360,
                        column: 25
                    },
                    end: {
                        line: 360,
                        column: 45
                    }
                },
                loc: {
                    start: {
                        line: 361,
                        column: 16
                    },
                    end: {
                        line: 380,
                        column: 17
                    }
                },
                line: 361
            },
            "24": {
                name: "trackLens",
                decl: {
                    start: {
                        line: 382,
                        column: 25
                    },
                    end: {
                        line: 382,
                        column: 34
                    }
                },
                loc: {
                    start: {
                        line: 383,
                        column: 16
                    },
                    end: {
                        line: 387,
                        column: 17
                    }
                },
                line: 383
            },
            "25": {
                name: "trackPointer",
                decl: {
                    start: {
                        line: 389,
                        column: 25
                    },
                    end: {
                        line: 389,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 390,
                        column: 16
                    },
                    end: {
                        line: 401,
                        column: 17
                    }
                },
                line: 390
            },
            "26": {
                name: "setLensSize",
                decl: {
                    start: {
                        line: 403,
                        column: 25
                    },
                    end: {
                        line: 403,
                        column: 36
                    }
                },
                loc: {
                    start: {
                        line: 404,
                        column: 16
                    },
                    end: {
                        line: 410,
                        column: 17
                    }
                },
                line: 404
            },
            "27": {
                name: "setLensPosition",
                decl: {
                    start: {
                        line: 412,
                        column: 25
                    },
                    end: {
                        line: 412,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 413,
                        column: 16
                    },
                    end: {
                        line: 442,
                        column: 17
                    }
                },
                line: 413
            },
            "28": {
                name: "getWindow",
                decl: {
                    start: {
                        line: 414,
                        column: 29
                    },
                    end: {
                        line: 414,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 415,
                        column: 20
                    },
                    end: {
                        line: 417,
                        column: 21
                    }
                },
                line: 415
            },
            "29": {
                name: "isWindow",
                decl: {
                    start: {
                        line: 419,
                        column: 29
                    },
                    end: {
                        line: 419,
                        column: 37
                    }
                },
                loc: {
                    start: {
                        line: 420,
                        column: 20
                    },
                    end: {
                        line: 422,
                        column: 21
                    }
                },
                line: 420
            },
            "30": {
                name: "updateThumbsPos",
                decl: {
                    start: {
                        line: 444,
                        column: 25
                    },
                    end: {
                        line: 444,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 445,
                        column: 16
                    },
                    end: {
                        line: 459,
                        column: 17
                    }
                },
                line: 445
            },
            "31": {
                name: "getSelectedIndex",
                decl: {
                    start: {
                        line: 461,
                        column: 25
                    },
                    end: {
                        line: 461,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 462,
                        column: 16
                    },
                    end: {
                        line: 470,
                        column: 17
                    }
                },
                line: 462
            },
            "32": {
                name: "zoomStateEnable",
                decl: {
                    start: {
                        line: 472,
                        column: 25
                    },
                    end: {
                        line: 472,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 473,
                        column: 16
                    },
                    end: {
                        line: 478,
                        column: 17
                    }
                },
                line: 473
            },
            "33": {
                name: "(anonymous_33)",
                decl: {
                    start: {
                        line: 474,
                        column: 38
                    },
                    end: {
                        line: 474,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 475,
                        column: 20
                    },
                    end: {
                        line: 477,
                        column: 21
                    }
                },
                line: 475
            },
            "34": {
                name: "zoomStateDisable",
                decl: {
                    start: {
                        line: 480,
                        column: 25
                    },
                    end: {
                        line: 480,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 481,
                        column: 16
                    },
                    end: {
                        line: 486,
                        column: 17
                    }
                },
                line: 481
            },
            "35": {
                name: "(anonymous_35)",
                decl: {
                    start: {
                        line: 482,
                        column: 38
                    },
                    end: {
                        line: 482,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 483,
                        column: 20
                    },
                    end: {
                        line: 485,
                        column: 21
                    }
                },
                line: 483
            },
            "36": {
                name: "watchSelectedModel",
                decl: {
                    start: {
                        line: 489,
                        column: 25
                    },
                    end: {
                        line: 489,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 490,
                        column: 16
                    },
                    end: {
                        line: 496,
                        column: 17
                    }
                },
                line: 490
            },
            "37": {
                name: "watchSelectedIndex",
                decl: {
                    start: {
                        line: 499,
                        column: 25
                    },
                    end: {
                        line: 499,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 500,
                        column: 16
                    },
                    end: {
                        line: 506,
                        column: 17
                    }
                },
                line: 500
            },
            "38": {
                name: "updateMainImage",
                decl: {
                    start: {
                        line: 508,
                        column: 25
                    },
                    end: {
                        line: 508,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 509,
                        column: 16
                    },
                    end: {
                        line: 522,
                        column: 17
                    }
                },
                line: 509
            },
            "39": {
                name: "registerWatchers",
                decl: {
                    start: {
                        line: 524,
                        column: 25
                    },
                    end: {
                        line: 524,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 525,
                        column: 16
                    },
                    end: {
                        line: 528,
                        column: 17
                    }
                },
                line: 525
            },
            "40": {
                name: "deRegisterWatchers",
                decl: {
                    start: {
                        line: 530,
                        column: 25
                    },
                    end: {
                        line: 530,
                        column: 43
                    }
                },
                loc: {
                    start: {
                        line: 531,
                        column: 16
                    },
                    end: {
                        line: 534,
                        column: 17
                    }
                },
                line: 531
            },
            "41": {
                name: "largeImageLoaded",
                decl: {
                    start: {
                        line: 536,
                        column: 25
                    },
                    end: {
                        line: 536,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 537,
                        column: 16
                    },
                    end: {
                        line: 540,
                        column: 17
                    }
                },
                line: 537
            },
            "42": {
                name: "(anonymous_42)",
                decl: {
                    start: {
                        line: 542,
                        column: 53
                    },
                    end: {
                        line: 542,
                        column: 54
                    }
                },
                loc: {
                    start: {
                        line: 543,
                        column: 16
                    },
                    end: {
                        line: 545,
                        column: 17
                    }
                },
                line: 543
            },
            "43": {
                name: "(anonymous_43)",
                decl: {
                    start: {
                        line: 549,
                        column: 64
                    },
                    end: {
                        line: 549,
                        column: 65
                    }
                },
                loc: {
                    start: {
                        line: 550,
                        column: 20
                    },
                    end: {
                        line: 552,
                        column: 21
                    }
                },
                line: 550
            },
            "44": {
                name: "(anonymous_44)",
                decl: {
                    start: {
                        line: 555,
                        column: 30
                    },
                    end: {
                        line: 555,
                        column: 31
                    }
                },
                loc: {
                    start: {
                        line: 556,
                        column: 16
                    },
                    end: {
                        line: 561,
                        column: 17
                    }
                },
                line: 556
            },
            "45": {
                name: "(anonymous_45)",
                decl: {
                    start: {
                        line: 561,
                        column: 19
                    },
                    end: {
                        line: 561,
                        column: 20
                    }
                },
                loc: {
                    start: {
                        line: 562,
                        column: 16
                    },
                    end: {
                        line: 567,
                        column: 17
                    }
                },
                line: 562
            },
            "46": {
                name: "(anonymous_46)",
                decl: {
                    start: {
                        line: 569,
                        column: 46
                    },
                    end: {
                        line: 569,
                        column: 47
                    }
                },
                loc: {
                    start: {
                        line: 570,
                        column: 16
                    },
                    end: {
                        line: 576,
                        column: 17
                    }
                },
                line: 570
            },
            "47": {
                name: "wipImageZoomLensDirective",
                decl: {
                    start: {
                        line: 581,
                        column: 13
                    },
                    end: {
                        line: 581,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 582,
                        column: 4
                    },
                    end: {
                        line: 591,
                        column: 5
                    }
                },
                line: 582
            },
            "48": {
                name: "(anonymous_48)",
                decl: {
                    start: {
                        line: 586,
                        column: 22
                    },
                    end: {
                        line: 586,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 587,
                        column: 12
                    },
                    end: {
                        line: 589,
                        column: 13
                    }
                },
                line: 587
            },
            "49": {
                name: "wipImageZoomTrackerDirective",
                decl: {
                    start: {
                        line: 593,
                        column: 13
                    },
                    end: {
                        line: 593,
                        column: 41
                    }
                },
                loc: {
                    start: {
                        line: 594,
                        column: 4
                    },
                    end: {
                        line: 603,
                        column: 5
                    }
                },
                line: 594
            },
            "50": {
                name: "(anonymous_50)",
                decl: {
                    start: {
                        line: 598,
                        column: 22
                    },
                    end: {
                        line: 598,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 599,
                        column: 12
                    },
                    end: {
                        line: 601,
                        column: 13
                    }
                },
                line: 599
            },
            "51": {
                name: "wipImageZoomMaskDirective",
                decl: {
                    start: {
                        line: 605,
                        column: 13
                    },
                    end: {
                        line: 605,
                        column: 38
                    }
                },
                loc: {
                    start: {
                        line: 606,
                        column: 4
                    },
                    end: {
                        line: 615,
                        column: 5
                    }
                },
                line: 606
            },
            "52": {
                name: "(anonymous_52)",
                decl: {
                    start: {
                        line: 610,
                        column: 22
                    },
                    end: {
                        line: 610,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 611,
                        column: 12
                    },
                    end: {
                        line: 613,
                        column: 13
                    }
                },
                line: 611
            },
            "53": {
                name: "wipImageZoomImageDirective",
                decl: {
                    start: {
                        line: 617,
                        column: 13
                    },
                    end: {
                        line: 617,
                        column: 39
                    }
                },
                loc: {
                    start: {
                        line: 618,
                        column: 4
                    },
                    end: {
                        line: 627,
                        column: 5
                    }
                },
                line: 618
            },
            "54": {
                name: "(anonymous_54)",
                decl: {
                    start: {
                        line: 622,
                        column: 22
                    },
                    end: {
                        line: 622,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 623,
                        column: 12
                    },
                    end: {
                        line: 625,
                        column: 13
                    }
                },
                line: 623
            },
            "55": {
                name: "wipImageZoomThumbsDirective",
                decl: {
                    start: {
                        line: 629,
                        column: 13
                    },
                    end: {
                        line: 629,
                        column: 40
                    }
                },
                loc: {
                    start: {
                        line: 630,
                        column: 4
                    },
                    end: {
                        line: 642,
                        column: 5
                    }
                },
                line: 630
            },
            "56": {
                name: "(anonymous_56)",
                decl: {
                    start: {
                        line: 635,
                        column: 22
                    },
                    end: {
                        line: 635,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 636,
                        column: 12
                    },
                    end: {
                        line: 640,
                        column: 13
                    }
                },
                line: 636
            },
            "57": {
                name: "imageOnLoadDirective",
                decl: {
                    start: {
                        line: 644,
                        column: 13
                    },
                    end: {
                        line: 644,
                        column: 33
                    }
                },
                loc: {
                    start: {
                        line: 645,
                        column: 4
                    },
                    end: {
                        line: 660,
                        column: 5
                    }
                },
                line: 645
            },
            "58": {
                name: "(anonymous_58)",
                decl: {
                    start: {
                        line: 648,
                        column: 22
                    },
                    end: {
                        line: 648,
                        column: 23
                    }
                },
                loc: {
                    start: {
                        line: 649,
                        column: 12
                    },
                    end: {
                        line: 658,
                        column: 13
                    }
                },
                line: 649
            },
            "59": {
                name: "(anonymous_59)",
                decl: {
                    start: {
                        line: 650,
                        column: 52
                    },
                    end: {
                        line: 650,
                        column: 53
                    }
                },
                loc: {
                    start: {
                        line: 651,
                        column: 16
                    },
                    end: {
                        line: 653,
                        column: 17
                    }
                },
                line: 651
            },
            "60": {
                name: "(anonymous_60)",
                decl: {
                    start: {
                        line: 654,
                        column: 53
                    },
                    end: {
                        line: 654,
                        column: 54
                    }
                },
                loc: {
                    start: {
                        line: 655,
                        column: 16
                    },
                    end: {
                        line: 657,
                        column: 17
                    }
                },
                line: 655
            }
        },
        branchMap: {
            "0": {
                loc: {
                    start: {
                        line: 120,
                        column: 33
                    },
                    end: {
                        line: 120,
                        column: 118
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 120,
                        column: 56
                    },
                    end: {
                        line: 120,
                        column: 67
                    }
                }, {
                    start: {
                        line: 120,
                        column: 70
                    },
                    end: {
                        line: 120,
                        column: 118
                    }
                }],
                line: 120
            },
            "1": {
                loc: {
                    start: {
                        line: 132,
                        column: 20
                    },
                    end: {
                        line: 141,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 132,
                        column: 20
                    },
                    end: {
                        line: 141,
                        column: 21
                    }
                }, {
                    start: {
                        line: 132,
                        column: 20
                    },
                    end: {
                        line: 141,
                        column: 21
                    }
                }],
                line: 132
            },
            "2": {
                loc: {
                    start: {
                        line: 150,
                        column: 20
                    },
                    end: {
                        line: 153,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 150,
                        column: 20
                    },
                    end: {
                        line: 153,
                        column: 21
                    }
                }, {
                    start: {
                        line: 150,
                        column: 20
                    },
                    end: {
                        line: 153,
                        column: 21
                    }
                }],
                line: 150
            },
            "3": {
                loc: {
                    start: {
                        line: 165,
                        column: 20
                    },
                    end: {
                        line: 168,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 165,
                        column: 20
                    },
                    end: {
                        line: 168,
                        column: 21
                    }
                }, {
                    start: {
                        line: 165,
                        column: 20
                    },
                    end: {
                        line: 168,
                        column: 21
                    }
                }],
                line: 165
            },
            "4": {
                loc: {
                    start: {
                        line: 174,
                        column: 20
                    },
                    end: {
                        line: 181,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 174,
                        column: 20
                    },
                    end: {
                        line: 181,
                        column: 21
                    }
                }, {
                    start: {
                        line: 174,
                        column: 20
                    },
                    end: {
                        line: 181,
                        column: 21
                    }
                }],
                line: 174
            },
            "5": {
                loc: {
                    start: {
                        line: 186,
                        column: 45
                    },
                    end: {
                        line: 186,
                        column: 159
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 186,
                        column: 45
                    },
                    end: {
                        line: 186,
                        column: 69
                    }
                }, {
                    start: {
                        line: 186,
                        column: 73
                    },
                    end: {
                        line: 186,
                        column: 118
                    }
                }, {
                    start: {
                        line: 186,
                        column: 122
                    },
                    end: {
                        line: 186,
                        column: 159
                    }
                }],
                line: 186
            },
            "6": {
                loc: {
                    start: {
                        line: 188,
                        column: 20
                    },
                    end: {
                        line: 191,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 188,
                        column: 20
                    },
                    end: {
                        line: 191,
                        column: 21
                    }
                }, {
                    start: {
                        line: 188,
                        column: 20
                    },
                    end: {
                        line: 191,
                        column: 21
                    }
                }],
                line: 188
            },
            "7": {
                loc: {
                    start: {
                        line: 188,
                        column: 25
                    },
                    end: {
                        line: 188,
                        column: 68
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 188,
                        column: 25
                    },
                    end: {
                        line: 188,
                        column: 47
                    }
                }, {
                    start: {
                        line: 188,
                        column: 51
                    },
                    end: {
                        line: 188,
                        column: 68
                    }
                }],
                line: 188
            },
            "8": {
                loc: {
                    start: {
                        line: 193,
                        column: 20
                    },
                    end: {
                        line: 196,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 193,
                        column: 20
                    },
                    end: {
                        line: 196,
                        column: 21
                    }
                }, {
                    start: {
                        line: 193,
                        column: 20
                    },
                    end: {
                        line: 196,
                        column: 21
                    }
                }],
                line: 193
            },
            "9": {
                loc: {
                    start: {
                        line: 193,
                        column: 25
                    },
                    end: {
                        line: 193,
                        column: 68
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 193,
                        column: 25
                    },
                    end: {
                        line: 193,
                        column: 48
                    }
                }, {
                    start: {
                        line: 193,
                        column: 52
                    },
                    end: {
                        line: 193,
                        column: 68
                    }
                }],
                line: 193
            },
            "10": {
                loc: {
                    start: {
                        line: 253,
                        column: 20
                    },
                    end: {
                        line: 256,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 253,
                        column: 20
                    },
                    end: {
                        line: 256,
                        column: 21
                    }
                }, {
                    start: {
                        line: 253,
                        column: 20
                    },
                    end: {
                        line: 256,
                        column: 21
                    }
                }],
                line: 253
            },
            "11": {
                loc: {
                    start: {
                        line: 266,
                        column: 24
                    },
                    end: {
                        line: 275,
                        column: 25
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 266,
                        column: 24
                    },
                    end: {
                        line: 275,
                        column: 25
                    }
                }, {
                    start: {
                        line: 266,
                        column: 24
                    },
                    end: {
                        line: 275,
                        column: 25
                    }
                }],
                line: 266
            },
            "12": {
                loc: {
                    start: {
                        line: 298,
                        column: 30
                    },
                    end: {
                        line: 298,
                        column: 55
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 298,
                        column: 44
                    },
                    end: {
                        line: 298,
                        column: 45
                    }
                }, {
                    start: {
                        line: 298,
                        column: 48
                    },
                    end: {
                        line: 298,
                        column: 55
                    }
                }],
                line: 298
            },
            "13": {
                loc: {
                    start: {
                        line: 299,
                        column: 30
                    },
                    end: {
                        line: 299,
                        column: 73
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 299,
                        column: 53
                    },
                    end: {
                        line: 299,
                        column: 63
                    }
                }, {
                    start: {
                        line: 299,
                        column: 66
                    },
                    end: {
                        line: 299,
                        column: 73
                    }
                }],
                line: 299
            },
            "14": {
                loc: {
                    start: {
                        line: 311,
                        column: 20
                    },
                    end: {
                        line: 325,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 311,
                        column: 20
                    },
                    end: {
                        line: 325,
                        column: 21
                    }
                }, {
                    start: {
                        line: 311,
                        column: 20
                    },
                    end: {
                        line: 325,
                        column: 21
                    }
                }],
                line: 311
            },
            "15": {
                loc: {
                    start: {
                        line: 311,
                        column: 25
                    },
                    end: {
                        line: 311,
                        column: 72
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 311,
                        column: 25
                    },
                    end: {
                        line: 311,
                        column: 51
                    }
                }, {
                    start: {
                        line: 311,
                        column: 55
                    },
                    end: {
                        line: 311,
                        column: 72
                    }
                }],
                line: 311
            },
            "16": {
                loc: {
                    start: {
                        line: 330,
                        column: 20
                    },
                    end: {
                        line: 352,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 330,
                        column: 20
                    },
                    end: {
                        line: 352,
                        column: 21
                    }
                }, {
                    start: {
                        line: 330,
                        column: 20
                    },
                    end: {
                        line: 352,
                        column: 21
                    }
                }],
                line: 330
            },
            "17": {
                loc: {
                    start: {
                        line: 337,
                        column: 24
                    },
                    end: {
                        line: 351,
                        column: 25
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 337,
                        column: 24
                    },
                    end: {
                        line: 351,
                        column: 25
                    }
                }, {
                    start: {
                        line: 337,
                        column: 24
                    },
                    end: {
                        line: 351,
                        column: 25
                    }
                }],
                line: 337
            },
            "18": {
                loc: {
                    start: {
                        line: 337,
                        column: 29
                    },
                    end: {
                        line: 337,
                        column: 104
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 337,
                        column: 29
                    },
                    end: {
                        line: 337,
                        column: 64
                    }
                }, {
                    start: {
                        line: 337,
                        column: 68
                    },
                    end: {
                        line: 337,
                        column: 104
                    }
                }],
                line: 337
            },
            "19": {
                loc: {
                    start: {
                        line: 340,
                        column: 28
                    },
                    end: {
                        line: 350,
                        column: 29
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 340,
                        column: 28
                    },
                    end: {
                        line: 350,
                        column: 29
                    }
                }, {
                    start: {
                        line: 340,
                        column: 28
                    },
                    end: {
                        line: 350,
                        column: 29
                    }
                }],
                line: 340
            },
            "20": {
                loc: {
                    start: {
                        line: 363,
                        column: 29
                    },
                    end: {
                        line: 363,
                        column: 80
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 363,
                        column: 29
                    },
                    end: {
                        line: 363,
                        column: 51
                    }
                }, {
                    start: {
                        line: 363,
                        column: 55
                    },
                    end: {
                        line: 363,
                        column: 64
                    }
                }, {
                    start: {
                        line: 363,
                        column: 68
                    },
                    end: {
                        line: 363,
                        column: 80
                    }
                }],
                line: 363
            },
            "21": {
                loc: {
                    start: {
                        line: 365,
                        column: 29
                    },
                    end: {
                        line: 365,
                        column: 54
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 365,
                        column: 29
                    },
                    end: {
                        line: 365,
                        column: 31
                    }
                }, {
                    start: {
                        line: 365,
                        column: 35
                    },
                    end: {
                        line: 365,
                        column: 43
                    }
                }, {
                    start: {
                        line: 365,
                        column: 47
                    },
                    end: {
                        line: 365,
                        column: 54
                    }
                }],
                line: 365
            },
            "22": {
                loc: {
                    start: {
                        line: 366,
                        column: 29
                    },
                    end: {
                        line: 366,
                        column: 54
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 366,
                        column: 29
                    },
                    end: {
                        line: 366,
                        column: 31
                    }
                }, {
                    start: {
                        line: 366,
                        column: 35
                    },
                    end: {
                        line: 366,
                        column: 43
                    }
                }, {
                    start: {
                        line: 366,
                        column: 47
                    },
                    end: {
                        line: 366,
                        column: 54
                    }
                }],
                line: 366
            },
            "23": {
                loc: {
                    start: {
                        line: 370,
                        column: 20
                    },
                    end: {
                        line: 378,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 370,
                        column: 20
                    },
                    end: {
                        line: 378,
                        column: 21
                    }
                }, {
                    start: {
                        line: 370,
                        column: 20
                    },
                    end: {
                        line: 378,
                        column: 21
                    }
                }],
                line: 370
            },
            "24": {
                loc: {
                    start: {
                        line: 395,
                        column: 27
                    },
                    end: {
                        line: 395,
                        column: 55
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 395,
                        column: 47
                    },
                    end: {
                        line: 395,
                        column: 48
                    }
                }, {
                    start: {
                        line: 395,
                        column: 51
                    },
                    end: {
                        line: 395,
                        column: 55
                    }
                }],
                line: 395
            },
            "25": {
                loc: {
                    start: {
                        line: 396,
                        column: 27
                    },
                    end: {
                        line: 396,
                        column: 55
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 396,
                        column: 47
                    },
                    end: {
                        line: 396,
                        column: 48
                    }
                }, {
                    start: {
                        line: 396,
                        column: 51
                    },
                    end: {
                        line: 396,
                        column: 55
                    }
                }],
                line: 396
            },
            "26": {
                loc: {
                    start: {
                        line: 397,
                        column: 27
                    },
                    end: {
                        line: 397,
                        column: 83
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 397,
                        column: 59
                    },
                    end: {
                        line: 397,
                        column: 75
                    }
                }, {
                    start: {
                        line: 397,
                        column: 79
                    },
                    end: {
                        line: 397,
                        column: 83
                    }
                }],
                line: 397
            },
            "27": {
                loc: {
                    start: {
                        line: 398,
                        column: 27
                    },
                    end: {
                        line: 398,
                        column: 83
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 398,
                        column: 59
                    },
                    end: {
                        line: 398,
                        column: 75
                    }
                }, {
                    start: {
                        line: 398,
                        column: 79
                    },
                    end: {
                        line: 398,
                        column: 83
                    }
                }],
                line: 398
            },
            "28": {
                loc: {
                    start: {
                        line: 416,
                        column: 31
                    },
                    end: {
                        line: 416,
                        column: 94
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 416,
                        column: 48
                    },
                    end: {
                        line: 416,
                        column: 52
                    }
                }, {
                    start: {
                        line: 416,
                        column: 55
                    },
                    end: {
                        line: 416,
                        column: 94
                    }
                }],
                line: 416
            },
            "29": {
                loc: {
                    start: {
                        line: 416,
                        column: 55
                    },
                    end: {
                        line: 416,
                        column: 94
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 416,
                        column: 55
                    },
                    end: {
                        line: 416,
                        column: 74
                    }
                }, {
                    start: {
                        line: 416,
                        column: 78
                    },
                    end: {
                        line: 416,
                        column: 94
                    }
                }],
                line: 416
            },
            "30": {
                loc: {
                    start: {
                        line: 421,
                        column: 31
                    },
                    end: {
                        line: 421,
                        column: 64
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 421,
                        column: 31
                    },
                    end: {
                        line: 421,
                        column: 42
                    }
                }, {
                    start: {
                        line: 421,
                        column: 46
                    },
                    end: {
                        line: 421,
                        column: 64
                    }
                }],
                line: 421
            },
            "31": {
                loc: {
                    start: {
                        line: 424,
                        column: 30
                    },
                    end: {
                        line: 424,
                        column: 76
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 424,
                        column: 30
                    },
                    end: {
                        line: 424,
                        column: 44
                    }
                }, {
                    start: {
                        line: 424,
                        column: 48
                    },
                    end: {
                        line: 424,
                        column: 76
                    }
                }],
                line: 424
            },
            "32": {
                loc: {
                    start: {
                        line: 435,
                        column: 31
                    },
                    end: {
                        line: 435,
                        column: 90
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 435,
                        column: 63
                    },
                    end: {
                        line: 435,
                        column: 79
                    }
                }, {
                    start: {
                        line: 435,
                        column: 82
                    },
                    end: {
                        line: 435,
                        column: 90
                    }
                }],
                line: 435
            },
            "33": {
                loc: {
                    start: {
                        line: 436,
                        column: 31
                    },
                    end: {
                        line: 436,
                        column: 58
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 436,
                        column: 46
                    },
                    end: {
                        line: 436,
                        column: 47
                    }
                }, {
                    start: {
                        line: 436,
                        column: 50
                    },
                    end: {
                        line: 436,
                        column: 58
                    }
                }],
                line: 436
            },
            "34": {
                loc: {
                    start: {
                        line: 438,
                        column: 31
                    },
                    end: {
                        line: 438,
                        column: 90
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 438,
                        column: 63
                    },
                    end: {
                        line: 438,
                        column: 79
                    }
                }, {
                    start: {
                        line: 438,
                        column: 82
                    },
                    end: {
                        line: 438,
                        column: 90
                    }
                }],
                line: 438
            },
            "35": {
                loc: {
                    start: {
                        line: 439,
                        column: 31
                    },
                    end: {
                        line: 439,
                        column: 58
                    }
                },
                type: "cond-expr",
                locations: [{
                    start: {
                        line: 439,
                        column: 46
                    },
                    end: {
                        line: 439,
                        column: 47
                    }
                }, {
                    start: {
                        line: 439,
                        column: 50
                    },
                    end: {
                        line: 439,
                        column: 58
                    }
                }],
                line: 439
            },
            "36": {
                loc: {
                    start: {
                        line: 446,
                        column: 20
                    },
                    end: {
                        line: 449,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 446,
                        column: 20
                    },
                    end: {
                        line: 449,
                        column: 21
                    }
                }, {
                    start: {
                        line: 446,
                        column: 20
                    },
                    end: {
                        line: 449,
                        column: 21
                    }
                }],
                line: 446
            },
            "37": {
                loc: {
                    start: {
                        line: 446,
                        column: 25
                    },
                    end: {
                        line: 446,
                        column: 88
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 446,
                        column: 25
                    },
                    end: {
                        line: 446,
                        column: 46
                    }
                }, {
                    start: {
                        line: 446,
                        column: 50
                    },
                    end: {
                        line: 446,
                        column: 88
                    }
                }],
                line: 446
            },
            "38": {
                loc: {
                    start: {
                        line: 452,
                        column: 35
                    },
                    end: {
                        line: 452,
                        column: 117
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 452,
                        column: 35
                    },
                    end: {
                        line: 452,
                        column: 85
                    }
                }, {
                    start: {
                        line: 452,
                        column: 89
                    },
                    end: {
                        line: 452,
                        column: 117
                    }
                }],
                line: 452
            },
            "39": {
                loc: {
                    start: {
                        line: 453,
                        column: 20
                    },
                    end: {
                        line: 457,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 453,
                        column: 20
                    },
                    end: {
                        line: 457,
                        column: 21
                    }
                }, {
                    start: {
                        line: 453,
                        column: 20
                    },
                    end: {
                        line: 457,
                        column: 21
                    }
                }],
                line: 453
            },
            "40": {
                loc: {
                    start: {
                        line: 465,
                        column: 24
                    },
                    end: {
                        line: 468,
                        column: 25
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 465,
                        column: 24
                    },
                    end: {
                        line: 468,
                        column: 25
                    }
                }, {
                    start: {
                        line: 465,
                        column: 24
                    },
                    end: {
                        line: 468,
                        column: 25
                    }
                }],
                line: 465
            },
            "41": {
                loc: {
                    start: {
                        line: 491,
                        column: 20
                    },
                    end: {
                        line: 495,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 491,
                        column: 20
                    },
                    end: {
                        line: 495,
                        column: 21
                    }
                }, {
                    start: {
                        line: 491,
                        column: 20
                    },
                    end: {
                        line: 495,
                        column: 21
                    }
                }],
                line: 491
            },
            "42": {
                loc: {
                    start: {
                        line: 491,
                        column: 25
                    },
                    end: {
                        line: 491,
                        column: 71
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 491,
                        column: 25
                    },
                    end: {
                        line: 491,
                        column: 50
                    }
                }, {
                    start: {
                        line: 491,
                        column: 54
                    },
                    end: {
                        line: 491,
                        column: 71
                    }
                }],
                line: 491
            },
            "43": {
                loc: {
                    start: {
                        line: 501,
                        column: 20
                    },
                    end: {
                        line: 505,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 501,
                        column: 20
                    },
                    end: {
                        line: 505,
                        column: 21
                    }
                }, {
                    start: {
                        line: 501,
                        column: 20
                    },
                    end: {
                        line: 505,
                        column: 21
                    }
                }],
                line: 501
            },
            "44": {
                loc: {
                    start: {
                        line: 501,
                        column: 25
                    },
                    end: {
                        line: 501,
                        column: 71
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 501,
                        column: 25
                    },
                    end: {
                        line: 501,
                        column: 50
                    }
                }, {
                    start: {
                        line: 501,
                        column: 54
                    },
                    end: {
                        line: 501,
                        column: 71
                    }
                }],
                line: 501
            },
            "45": {
                loc: {
                    start: {
                        line: 547,
                        column: 16
                    },
                    end: {
                        line: 553,
                        column: 17
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 547,
                        column: 16
                    },
                    end: {
                        line: 553,
                        column: 17
                    }
                }, {
                    start: {
                        line: 547,
                        column: 16
                    },
                    end: {
                        line: 553,
                        column: 17
                    }
                }],
                line: 547
            },
            "46": {
                loc: {
                    start: {
                        line: 563,
                        column: 20
                    },
                    end: {
                        line: 566,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 563,
                        column: 20
                    },
                    end: {
                        line: 566,
                        column: 21
                    }
                }, {
                    start: {
                        line: 563,
                        column: 20
                    },
                    end: {
                        line: 566,
                        column: 21
                    }
                }],
                line: 563
            },
            "47": {
                loc: {
                    start: {
                        line: 563,
                        column: 25
                    },
                    end: {
                        line: 563,
                        column: 71
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 563,
                        column: 25
                    },
                    end: {
                        line: 563,
                        column: 50
                    }
                }, {
                    start: {
                        line: 563,
                        column: 54
                    },
                    end: {
                        line: 563,
                        column: 71
                    }
                }],
                line: 563
            },
            "48": {
                loc: {
                    start: {
                        line: 571,
                        column: 20
                    },
                    end: {
                        line: 575,
                        column: 21
                    }
                },
                type: "if",
                locations: [{
                    start: {
                        line: 571,
                        column: 20
                    },
                    end: {
                        line: 575,
                        column: 21
                    }
                }, {
                    start: {
                        line: 571,
                        column: 20
                    },
                    end: {
                        line: 575,
                        column: 21
                    }
                }],
                line: 571
            },
            "49": {
                loc: {
                    start: {
                        line: 571,
                        column: 25
                    },
                    end: {
                        line: 571,
                        column: 71
                    }
                },
                type: "binary-expr",
                locations: [{
                    start: {
                        line: 571,
                        column: 25
                    },
                    end: {
                        line: 571,
                        column: 50
                    }
                }, {
                    start: {
                        line: 571,
                        column: 54
                    },
                    end: {
                        line: 571,
                        column: 71
                    }
                }],
                line: 571
            }
        },
        s: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0,
            "12": 0,
            "13": 0,
            "14": 0,
            "15": 0,
            "16": 0,
            "17": 0,
            "18": 0,
            "19": 0,
            "20": 0,
            "21": 0,
            "22": 0,
            "23": 0,
            "24": 0,
            "25": 0,
            "26": 0,
            "27": 0,
            "28": 0,
            "29": 0,
            "30": 0,
            "31": 0,
            "32": 0,
            "33": 0,
            "34": 0,
            "35": 0,
            "36": 0,
            "37": 0,
            "38": 0,
            "39": 0,
            "40": 0,
            "41": 0,
            "42": 0,
            "43": 0,
            "44": 0,
            "45": 0,
            "46": 0,
            "47": 0,
            "48": 0,
            "49": 0,
            "50": 0,
            "51": 0,
            "52": 0,
            "53": 0,
            "54": 0,
            "55": 0,
            "56": 0,
            "57": 0,
            "58": 0,
            "59": 0,
            "60": 0,
            "61": 0,
            "62": 0,
            "63": 0,
            "64": 0,
            "65": 0,
            "66": 0,
            "67": 0,
            "68": 0,
            "69": 0,
            "70": 0,
            "71": 0,
            "72": 0,
            "73": 0,
            "74": 0,
            "75": 0,
            "76": 0,
            "77": 0,
            "78": 0,
            "79": 0,
            "80": 0,
            "81": 0,
            "82": 0,
            "83": 0,
            "84": 0,
            "85": 0,
            "86": 0,
            "87": 0,
            "88": 0,
            "89": 0,
            "90": 0,
            "91": 0,
            "92": 0,
            "93": 0,
            "94": 0,
            "95": 0,
            "96": 0,
            "97": 0,
            "98": 0,
            "99": 0,
            "100": 0,
            "101": 0,
            "102": 0,
            "103": 0,
            "104": 0,
            "105": 0,
            "106": 0,
            "107": 0,
            "108": 0,
            "109": 0,
            "110": 0,
            "111": 0,
            "112": 0,
            "113": 0,
            "114": 0,
            "115": 0,
            "116": 0,
            "117": 0,
            "118": 0,
            "119": 0,
            "120": 0,
            "121": 0,
            "122": 0,
            "123": 0,
            "124": 0,
            "125": 0,
            "126": 0,
            "127": 0,
            "128": 0,
            "129": 0,
            "130": 0,
            "131": 0,
            "132": 0,
            "133": 0,
            "134": 0,
            "135": 0,
            "136": 0,
            "137": 0,
            "138": 0,
            "139": 0,
            "140": 0,
            "141": 0,
            "142": 0,
            "143": 0,
            "144": 0,
            "145": 0,
            "146": 0,
            "147": 0,
            "148": 0,
            "149": 0,
            "150": 0,
            "151": 0,
            "152": 0,
            "153": 0,
            "154": 0,
            "155": 0,
            "156": 0,
            "157": 0,
            "158": 0,
            "159": 0,
            "160": 0,
            "161": 0,
            "162": 0,
            "163": 0,
            "164": 0,
            "165": 0,
            "166": 0,
            "167": 0,
            "168": 0,
            "169": 0,
            "170": 0,
            "171": 0,
            "172": 0,
            "173": 0,
            "174": 0,
            "175": 0,
            "176": 0,
            "177": 0,
            "178": 0,
            "179": 0,
            "180": 0,
            "181": 0,
            "182": 0,
            "183": 0,
            "184": 0,
            "185": 0,
            "186": 0,
            "187": 0,
            "188": 0,
            "189": 0,
            "190": 0,
            "191": 0,
            "192": 0,
            "193": 0,
            "194": 0,
            "195": 0,
            "196": 0,
            "197": 0,
            "198": 0,
            "199": 0,
            "200": 0,
            "201": 0,
            "202": 0,
            "203": 0,
            "204": 0,
            "205": 0,
            "206": 0,
            "207": 0,
            "208": 0,
            "209": 0,
            "210": 0,
            "211": 0,
            "212": 0,
            "213": 0,
            "214": 0,
            "215": 0,
            "216": 0,
            "217": 0,
            "218": 0,
            "219": 0,
            "220": 0,
            "221": 0,
            "222": 0,
            "223": 0,
            "224": 0,
            "225": 0,
            "226": 0,
            "227": 0,
            "228": 0,
            "229": 0,
            "230": 0,
            "231": 0,
            "232": 0,
            "233": 0,
            "234": 0,
            "235": 0,
            "236": 0,
            "237": 0,
            "238": 0,
            "239": 0,
            "240": 0,
            "241": 0,
            "242": 0,
            "243": 0,
            "244": 0,
            "245": 0,
            "246": 0,
            "247": 0,
            "248": 0
        },
        f: {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "8": 0,
            "9": 0,
            "10": 0,
            "11": 0,
            "12": 0,
            "13": 0,
            "14": 0,
            "15": 0,
            "16": 0,
            "17": 0,
            "18": 0,
            "19": 0,
            "20": 0,
            "21": 0,
            "22": 0,
            "23": 0,
            "24": 0,
            "25": 0,
            "26": 0,
            "27": 0,
            "28": 0,
            "29": 0,
            "30": 0,
            "31": 0,
            "32": 0,
            "33": 0,
            "34": 0,
            "35": 0,
            "36": 0,
            "37": 0,
            "38": 0,
            "39": 0,
            "40": 0,
            "41": 0,
            "42": 0,
            "43": 0,
            "44": 0,
            "45": 0,
            "46": 0,
            "47": 0,
            "48": 0,
            "49": 0,
            "50": 0,
            "51": 0,
            "52": 0,
            "53": 0,
            "54": 0,
            "55": 0,
            "56": 0,
            "57": 0,
            "58": 0,
            "59": 0,
            "60": 0
        },
        b: {
            "0": [0, 0],
            "1": [0, 0],
            "2": [0, 0],
            "3": [0, 0],
            "4": [0, 0],
            "5": [0, 0, 0],
            "6": [0, 0],
            "7": [0, 0],
            "8": [0, 0],
            "9": [0, 0],
            "10": [0, 0],
            "11": [0, 0],
            "12": [0, 0],
            "13": [0, 0],
            "14": [0, 0],
            "15": [0, 0],
            "16": [0, 0],
            "17": [0, 0],
            "18": [0, 0],
            "19": [0, 0],
            "20": [0, 0, 0],
            "21": [0, 0, 0],
            "22": [0, 0, 0],
            "23": [0, 0],
            "24": [0, 0],
            "25": [0, 0],
            "26": [0, 0],
            "27": [0, 0],
            "28": [0, 0],
            "29": [0, 0],
            "30": [0, 0],
            "31": [0, 0],
            "32": [0, 0],
            "33": [0, 0],
            "34": [0, 0],
            "35": [0, 0],
            "36": [0, 0],
            "37": [0, 0],
            "38": [0, 0],
            "39": [0, 0],
            "40": [0, 0],
            "41": [0, 0],
            "42": [0, 0],
            "43": [0, 0],
            "44": [0, 0],
            "45": [0, 0],
            "46": [0, 0],
            "47": [0, 0],
            "48": [0, 0],
            "49": [0, 0]
        },
        _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
    },
        coverage = global[gcv] || (global[gcv] = {});

    if (coverage[path] && coverage[path].hash === hash) {
        return coverage[path];
    }

    coverageData.hash = hash;
    return coverage[path] = coverageData;
}();

cov_1x18pfp9by.s[0]++;
(function () {
    'use strict';

    cov_1x18pfp9by.f[0]++;
    cov_1x18pfp9by.s[1]++;
    imageOnLoadDirective.$inject = ["$log"];
    cov_1x18pfp9by.s[2]++;
    wipImageZoomDirective.$inject = ["$timeout"];
    cov_1x18pfp9by.s[3]++;
    angular.module('wipImageZoom', ['ngSanitize', 'ngTouch']).provider('wipImageZoomConfig', wipImageZoomConfig).directive('imageOnLoad', imageOnLoadDirective).directive('wipImageZoom', wipImageZoomDirective).directive('wipImageZoomTracker', wipImageZoomTrackerDirective).directive('wipImageZoomLens', wipImageZoomLensDirective).directive('wipImageZoomMask', wipImageZoomMaskDirective).directive('wipImageZoomImage', wipImageZoomImageDirective).directive('wipImageZoomThumbs', wipImageZoomThumbsDirective);

    function wipImageZoomConfig() {
        cov_1x18pfp9by.f[1]++;
        cov_1x18pfp9by.s[4]++;

        this.defaults = {
            zoomEnable: true,
            defaultIndex: 0, // Order of the default selected Image
            /* You should give images in array with object properties
             [{
             thumb : 'assets/images/1-thumb.jpg',
             medium: 'assets/images/1-medium.jpg',
             large : 'assets/images/1-large.jpg'
             }] */
            images: [],
            style: 'inner', // inner or box
            boxPos: 'right-top', // e.g., right-top, right-middle, right-bottom, top-center, top-left, top-right ...
            boxW: 400, // Box width
            boxH: 400, // Box height
            method: 'lens', // fallow 'lens' or 'pointer'
            cursor: 'crosshair', // 'none', 'default', 'crosshair', 'pointer', 'move'
            lens: true, // Lens toggle
            zoomLevel: 3, // 0: not scales, uses the original large image size, use 1 and above to adjust.
            immersiveMode: '769', // false or 0 for disable, always, max width(px) for trigger
            immersiveModeOptions: {}, // can extend immersed mode options
            immersiveModeMessage: 'Click to Zoom', // Immersive mode message
            prevThumbButton: '&#9665;', // Prev thumb button (html)
            nextThumbButton: '&#9655;', // Next thumb button (html)
            thumbsPos: 'bottom', // Thumbs position: 'top', 'bottom'
            thumbCol: 3, // Thumb column count
            thumbColPadding: 4 // Padding between thumbs
        };

        cov_1x18pfp9by.s[5]++;
        this.setDefaults = function (defaults) {
            cov_1x18pfp9by.f[2]++;
            cov_1x18pfp9by.s[6]++;

            this.defaults = angular.extend(this.defaults, defaults);
        };

        cov_1x18pfp9by.s[7]++;
        this.$get = function () {
            cov_1x18pfp9by.f[3]++;
            cov_1x18pfp9by.s[8]++;

            return this;
        };
    }

    function wipImageZoomDirective($timeout) {
        cov_1x18pfp9by.f[4]++;
        cov_1x18pfp9by.s[9]++;

        return {
            restrict: 'EA',
            template: '<div class="wip-image-zoom {{vm.options.style}}-style {{vm.options.thumbsPos}}-thumbs"\n     ng-class="{\n     \'active\':vm.zoomActive, \n     \'immersive-mode\':vm.immersiveModeActive && !immersive,\n     \'zoom-disabled\':!vm.options.zoomEnable,\n     \'box-style\':vm.options.style == \'box\' ,\n     \'inner-style\':vm.options.style == \'inner\'}">\n\n    <wip-image-zoom-thumbs ng-if="vm.options.thumbsPos === \'top\' && vm.images.length > 1"></wip-image-zoom-thumbs>\n\n    <div ng-hide="!vm.options.zoomEnable && immersive" class="main-image-wrapper" ng-class="{\'loading\':vm.largeImageLoading}">\n        <div class="image-zoom-tracker" wip-image-zoom-tracker></div>\n        <div class="image-zoom-lens" wip-image-zoom-lens></div>\n        <img class="main-image" ng-src="{{vm.mainImage.medium}}" image-on-load="vm.initZoom()">\n        <div class="zoom-mask"\n             ng-class="vm.options.style == \'box\'? vm.options.boxPos : \'\'"\n             wip-image-zoom-mask>\n            <img wip-image-zoom-image class="zoom-image main-image-large" image-on-load="vm.largeImageLoaded()"\n                 ng-src="{{vm.mainImage.large}}">\n        </div>\n        <div ng-if="vm.immersiveModeActive && !immersive && vm.options.immersiveModeMessage !== \'\'"\n             class="immersive-mode-message" ng-bind="vm.options.immersiveModeMessage"></div>\n    </div>\n\n    <div class="immersive-no-zoom-image-wrapper" ng-show="!vm.options.zoomEnable && immersive">\n        <img class="main-image-large" ng-src="{{vm.mainImage.large}}">\n    </div>\n\n    <wip-image-zoom-thumbs\n            ng-if="vm.options.thumbsPos === \'bottom\' && vm.images.length > 1"></wip-image-zoom-thumbs>\n</div>',
            replace: true,
            scope: {
                selectedModel: '=?',
                selectedIndex: '=?',
                wipImageZoom: '=',
                immersive: '=?'
            },
            controllerAs: 'vm',
            link: function link(scope, element, attrs, ctrl) {
                cov_1x18pfp9by.f[5]++;
                cov_1x18pfp9by.s[10]++;

                ctrl.el = element;
                cov_1x18pfp9by.s[11]++;
                ctrl.attrs = attrs;
                cov_1x18pfp9by.s[12]++;
                ctrl.init();
            },
            controller: ["$scope", "$document", "$window", "$compile", "wipImageZoomConfig", function ($scope, $document, $window, $compile, wipImageZoomConfig) {
                cov_1x18pfp9by.f[6]++;

                var vm = (cov_1x18pfp9by.s[13]++, this),
                    evPosX,
                    evPosY,
                    trackerW,
                    trackerH,
                    trackerL,
                    trackerT,
                    maskW,
                    maskH,
                    zoomImgW,
                    zoomImgH,
                    lensW,
                    lensH,
                    lensPosX,
                    lensPosY,
                    zoomLevelRatio,
                    defaultOpts = (cov_1x18pfp9by.s[14]++, angular.copy(wipImageZoomConfig.defaults)),
                    updateTimeout = (cov_1x18pfp9by.s[15]++, true),
                    unWatchSelectedIndex,
                    unWatchSelectedModel;

                cov_1x18pfp9by.s[16]++;
                vm.el = {};
                cov_1x18pfp9by.s[17]++;
                vm.zoomTracker = {};
                cov_1x18pfp9by.s[18]++;
                vm.zoomLens = {};
                cov_1x18pfp9by.s[19]++;
                vm.zoomImageEl = {};
                cov_1x18pfp9by.s[20]++;
                vm.thumbsWrapper = {};
                cov_1x18pfp9by.s[21]++;
                vm.thumbsEl = {};
                cov_1x18pfp9by.s[22]++;
                vm.mainImage = {};
                cov_1x18pfp9by.s[23]++;
                vm.options = {};
                cov_1x18pfp9by.s[24]++;
                vm.images = [];
                cov_1x18pfp9by.s[25]++;
                vm.zoomActive = false;
                cov_1x18pfp9by.s[26]++;
                vm.largeImageLoading = true;

                cov_1x18pfp9by.s[27]++;
                vm.prevThumbActive = false;
                cov_1x18pfp9by.s[28]++;
                vm.nextThumbActive = false;
                cov_1x18pfp9by.s[29]++;
                vm.thumbWidth = 0;
                cov_1x18pfp9by.s[30]++;
                vm.thumbsWrapperWidth = 0;
                cov_1x18pfp9by.s[31]++;
                vm.thumbsWidth = 0;
                cov_1x18pfp9by.s[32]++;
                vm.thumbsPos = 0;
                cov_1x18pfp9by.s[33]++;
                vm.immersiveModeActive = false;

                cov_1x18pfp9by.s[34]++;
                vm.init = init;
                cov_1x18pfp9by.s[35]++;
                vm.initZoom = initZoom;
                cov_1x18pfp9by.s[36]++;
                vm.initThumbs = initThumbs;
                cov_1x18pfp9by.s[37]++;
                vm.largeImageLoaded = largeImageLoaded;

                cov_1x18pfp9by.s[38]++;
                vm.updateMainImage = updateMainImage;
                cov_1x18pfp9by.s[39]++;
                vm.nextThumb = nextThumb;
                cov_1x18pfp9by.s[40]++;
                vm.prevThumb = prevThumb;
                cov_1x18pfp9by.s[41]++;
                vm.disableImmersiveMode = disableImmersiveMode;

                function init() {
                    cov_1x18pfp9by.f[7]++;
                    cov_1x18pfp9by.s[42]++;

                    vm.options = !$scope.wipImageZoom ? (cov_1x18pfp9by.b[0][0]++, defaultOpts) : (cov_1x18pfp9by.b[0][1]++, angular.extend(defaultOpts, $scope.wipImageZoom));

                    cov_1x18pfp9by.s[43]++;
                    setImages();

                    cov_1x18pfp9by.s[44]++;
                    $scope.selectedIndex = vm.options.defaultIndex;
                    cov_1x18pfp9by.s[45]++;
                    $scope.selectedModel = vm.mainImage;

                    cov_1x18pfp9by.s[46]++;
                    registerWatchers();
                }

                function setImages() {
                    cov_1x18pfp9by.f[8]++;
                    cov_1x18pfp9by.s[47]++;

                    if (vm.options.images.length <= 0) {
                        cov_1x18pfp9by.b[1][0]++;
                        cov_1x18pfp9by.s[48]++;

                        vm.options.images = [{
                            thumb: vm.attrs.src,
                            medium: vm.attrs.src,
                            large: vm.attrs.src
                        }];
                    } else {
                        cov_1x18pfp9by.b[1][1]++;
                    }

                    cov_1x18pfp9by.s[49]++;
                    vm.images = vm.options.images;
                    cov_1x18pfp9by.s[50]++;
                    vm.mainImage = vm.images[vm.options.defaultIndex];
                }

                function update() {
                    cov_1x18pfp9by.f[9]++;
                    cov_1x18pfp9by.s[51]++;

                    // Debounce for update
                    if (updateTimeout) {
                        cov_1x18pfp9by.b[2][0]++;
                        cov_1x18pfp9by.s[52]++;

                        $timeout.cancel(updateTimeout);
                    } else {
                        cov_1x18pfp9by.b[2][1]++;
                    }

                    cov_1x18pfp9by.s[53]++;
                    updateTimeout = $timeout(function () {
                        cov_1x18pfp9by.f[10]++;
                        cov_1x18pfp9by.s[54]++;

                        initThumbs();
                        cov_1x18pfp9by.s[55]++;
                        initZoom();
                        cov_1x18pfp9by.s[56]++;
                        updateThumbsPos();
                    }, 400);
                }

                function initZoom() {
                    cov_1x18pfp9by.f[11]++;
                    cov_1x18pfp9by.s[57]++;

                    if (!vm.options.zoomEnable) {
                        cov_1x18pfp9by.b[3][0]++;
                        cov_1x18pfp9by.s[58]++;

                        return;
                    } else {
                        cov_1x18pfp9by.b[3][1]++;
                    }

                    cov_1x18pfp9by.s[59]++;
                    initSizes();

                    cov_1x18pfp9by.s[60]++;
                    vm.zoomTracker.style.cursor = vm.options.cursor;

                    cov_1x18pfp9by.s[61]++;
                    if (vm.options.lens) {
                        cov_1x18pfp9by.b[4][0]++;
                        cov_1x18pfp9by.s[62]++;

                        vm.zoomLens.style.display = 'block';
                    } else {
                        cov_1x18pfp9by.b[4][1]++;
                        cov_1x18pfp9by.s[63]++;

                        vm.zoomLens.style.display = 'none';
                    }

                    // Reset Event Listeners
                    cov_1x18pfp9by.s[64]++;
                    removeEventListeners();

                    cov_1x18pfp9by.s[65]++;
                    vm.immersiveModeActive = (cov_1x18pfp9by.b[5][0]++, vm.options.immersiveMode) && (cov_1x18pfp9by.b[5][1]++, vm.options.immersiveMode > $window.innerWidth) || (cov_1x18pfp9by.b[5][2]++, vm.options.immersiveMode === 'always');

                    cov_1x18pfp9by.s[66]++;
                    if ((cov_1x18pfp9by.b[7][0]++, vm.immersiveModeActive) && (cov_1x18pfp9by.b[7][1]++, !$scope.immersive)) {
                        cov_1x18pfp9by.b[6][0]++;
                        cov_1x18pfp9by.s[67]++;

                        vm.zoomTracker.addEventListener('mousedown', enableImmersiveMode);
                    } else {
                        cov_1x18pfp9by.b[6][1]++;
                    }

                    cov_1x18pfp9by.s[68]++;
                    if ((cov_1x18pfp9by.b[9][0]++, !vm.immersiveModeActive) || (cov_1x18pfp9by.b[9][1]++, $scope.immersive)) {
                        cov_1x18pfp9by.b[8][0]++;
                        cov_1x18pfp9by.s[69]++;

                        addEventListeners();
                    } else {
                        cov_1x18pfp9by.b[8][1]++;
                    }
                }

                function addEventListeners() {
                    cov_1x18pfp9by.f[12]++;
                    cov_1x18pfp9by.s[70]++;

                    vm.zoomTracker.addEventListener('mousemove', zoomStateEnable);
                    cov_1x18pfp9by.s[71]++;
                    vm.zoomTracker.addEventListener('touchstart', zoomStateEnable);

                    cov_1x18pfp9by.s[72]++;
                    vm.zoomTracker.addEventListener('mouseleave', zoomStateDisable);
                    cov_1x18pfp9by.s[73]++;
                    vm.zoomTracker.addEventListener('touchend', zoomStateDisable);

                    cov_1x18pfp9by.s[74]++;
                    vm.zoomTracker.addEventListener('mousemove', setZoomImagePosition);
                    cov_1x18pfp9by.s[75]++;
                    vm.zoomTracker.addEventListener('touchmove', setZoomImagePosition);
                }

                function removeEventListeners() {
                    cov_1x18pfp9by.f[13]++;
                    cov_1x18pfp9by.s[76]++;

                    vm.zoomTracker.removeEventListener('mousedown', enableImmersiveMode);

                    cov_1x18pfp9by.s[77]++;
                    vm.zoomTracker.removeEventListener('mousemove', zoomStateEnable);
                    cov_1x18pfp9by.s[78]++;
                    vm.zoomTracker.removeEventListener('touchstart', zoomStateEnable);

                    cov_1x18pfp9by.s[79]++;
                    vm.zoomTracker.removeEventListener('mouseleave', zoomStateDisable);
                    cov_1x18pfp9by.s[80]++;
                    vm.zoomTracker.removeEventListener('touchend', zoomStateDisable);

                    cov_1x18pfp9by.s[81]++;
                    vm.zoomTracker.removeEventListener('mousemove', setZoomImagePosition);
                    cov_1x18pfp9by.s[82]++;
                    vm.zoomTracker.removeEventListener('touchmove', setZoomImagePosition);
                }

                function disableImmersiveMode() {
                    cov_1x18pfp9by.f[14]++;
                    cov_1x18pfp9by.s[83]++;

                    $document.find('html').removeClass('wip-image-zoom-immersive-mode-enabled');
                    cov_1x18pfp9by.s[84]++;
                    removeEventListeners();
                    cov_1x18pfp9by.s[85]++;
                    vm.immersedEl.remove();
                    cov_1x18pfp9by.s[86]++;
                    update();
                }

                function enableImmersiveMode(ev) {
                    cov_1x18pfp9by.f[15]++;
                    cov_1x18pfp9by.s[87]++;

                    ev.preventDefault();
                    cov_1x18pfp9by.s[88]++;
                    ev.stopPropagation();
                    cov_1x18pfp9by.s[89]++;
                    $scope.$apply(function () {
                        cov_1x18pfp9by.f[16]++;
                        cov_1x18pfp9by.s[90]++;

                        $document.find('html').addClass('wip-image-zoom-immersive-mode-enabled');
                        var body = (cov_1x18pfp9by.s[91]++, $document.find('body').eq(0));
                        cov_1x18pfp9by.s[92]++;
                        vm.immersedImageOpt = angular.extend(angular.copy(vm.options), vm.options.immersiveModeOptions);
                        cov_1x18pfp9by.s[93]++;
                        vm.immersedImageOpt.defaultIndex = $scope.selectedIndex;
                        cov_1x18pfp9by.s[94]++;
                        vm.immersedImageOpt.style = 'inner';
                        cov_1x18pfp9by.s[95]++;
                        vm.immersedEl = $compile('<div class="immersive-wip-image-zoom">\n    <div class="disable-immersive-mode-button" ng-click="vm.disableImmersiveMode()">&#10006;</div>\n    <img src="" wip-image-zoom="vm.immersedImageOpt" immersive="true" selected-index="selectedIndex">\n</div>\n')($scope);
                        cov_1x18pfp9by.s[96]++;
                        body.append(vm.immersedEl);
                        cov_1x18pfp9by.s[97]++;
                        update();
                    });
                }

                function initThumbs() {
                    cov_1x18pfp9by.f[17]++;
                    cov_1x18pfp9by.s[98]++;

                    if (vm.images.length <= 1) {
                        cov_1x18pfp9by.b[10][0]++;
                        cov_1x18pfp9by.s[99]++;

                        return;
                    } else {
                        cov_1x18pfp9by.b[10][1]++;
                    }

                    cov_1x18pfp9by.s[100]++;
                    vm.thumbsWrapperWidth = vm.thumbsWrapper.clientWidth;
                    cov_1x18pfp9by.s[101]++;
                    vm.thumbWidth = Math.round((vm.thumbsWrapperWidth + vm.options.thumbColPadding) / vm.options.thumbCol);
                    cov_1x18pfp9by.s[102]++;
                    vm.thumbsWidth = vm.thumbWidth * vm.images.length;
                    cov_1x18pfp9by.s[103]++;
                    vm.maxPosX = vm.images.length - vm.options.thumbCol;

                    // Set Thumbnail width
                    cov_1x18pfp9by.s[104]++;
                    $scope.$evalAsync(function () {
                        cov_1x18pfp9by.f[18]++;
                        cov_1x18pfp9by.s[105]++;

                        if (vm.options.thumbsPos === 'top') {
                            cov_1x18pfp9by.b[11][0]++;
                            cov_1x18pfp9by.s[106]++;

                            vm.thumbsEl.style.paddingBottom = vm.options.thumbColPadding + 'px';
                            cov_1x18pfp9by.s[107]++;
                            vm.thumbsEl.style.paddingTop = 0;
                        } else {
                            cov_1x18pfp9by.b[11][1]++;
                            cov_1x18pfp9by.s[108]++;

                            vm.thumbsEl.style.paddingTop = vm.options.thumbColPadding + 'px';
                            cov_1x18pfp9by.s[109]++;
                            vm.thumbsEl.style.paddingBottom = 0;
                        }

                        cov_1x18pfp9by.s[110]++;
                        for (var i = 0; i < vm.thumbsEl.children.length; i++) {
                            var thumb = (cov_1x18pfp9by.s[111]++, vm.thumbsEl.children[i]);
                            cov_1x18pfp9by.s[112]++;
                            thumb.style.width = vm.thumbWidth + 'px';
                            cov_1x18pfp9by.s[113]++;
                            thumb.style.paddingRight = vm.options.thumbColPadding + 'px';
                        }
                    });
                }

                function nextThumb() {
                    cov_1x18pfp9by.f[19]++;
                    cov_1x18pfp9by.s[114]++;

                    scrollThumbs(vm.thumbsPos + 1);
                }

                function prevThumb() {
                    cov_1x18pfp9by.f[20]++;
                    cov_1x18pfp9by.s[115]++;

                    scrollThumbs(vm.thumbsPos - 1);
                }

                function scrollThumbs(newPosX) {
                    cov_1x18pfp9by.f[21]++;
                    cov_1x18pfp9by.s[116]++;

                    newPosX = newPosX < 0 ? (cov_1x18pfp9by.b[12][0]++, 0) : (cov_1x18pfp9by.b[12][1]++, newPosX);
                    cov_1x18pfp9by.s[117]++;
                    newPosX = newPosX > vm.maxPosX ? (cov_1x18pfp9by.b[13][0]++, vm.maxPosX) : (cov_1x18pfp9by.b[13][1]++, newPosX);
                    cov_1x18pfp9by.s[118]++;
                    vm.thumbsPos = newPosX;
                    var scrollX = (cov_1x18pfp9by.s[119]++, vm.thumbsPos * vm.thumbWidth * -1);
                    cov_1x18pfp9by.s[120]++;
                    vm.thumbsEl.style.transform = 'translate3d(' + scrollX + 'px, 0px, 0)';
                }

                function initSizes() {
                    cov_1x18pfp9by.f[22]++;

                    var trackerPos = (cov_1x18pfp9by.s[121]++, vm.zoomTracker.getBoundingClientRect());
                    cov_1x18pfp9by.s[122]++;
                    trackerW = trackerPos.width;
                    cov_1x18pfp9by.s[123]++;
                    trackerH = trackerPos.height;
                    // Box Style
                    cov_1x18pfp9by.s[124]++;
                    if ((cov_1x18pfp9by.b[15][0]++, vm.options.style === 'box') && (cov_1x18pfp9by.b[15][1]++, !$scope.immersive)) {
                        cov_1x18pfp9by.b[14][0]++;
                        cov_1x18pfp9by.s[125]++;

                        maskW = vm.options.boxW;
                        cov_1x18pfp9by.s[126]++;
                        maskH = vm.options.boxH;
                        cov_1x18pfp9by.s[127]++;
                        vm.zoomMaskEl.style.width = maskW + 'px';
                        cov_1x18pfp9by.s[128]++;
                        vm.zoomMaskEl.style.height = maskH + 'px';
                    }
                    // Inner Style
                    else {
                            cov_1x18pfp9by.b[14][1]++;
                            cov_1x18pfp9by.s[129]++;

                            maskW = trackerW;
                            cov_1x18pfp9by.s[130]++;
                            maskH = trackerH;
                            cov_1x18pfp9by.s[131]++;
                            vm.zoomMaskEl.style.width = '100%';
                            cov_1x18pfp9by.s[132]++;
                            vm.zoomMaskEl.style.height = '100%';
                        }

                    cov_1x18pfp9by.s[133]++;
                    vm.zoomImageEl.style.width = '';
                    cov_1x18pfp9by.s[134]++;
                    vm.zoomImageEl.style.height = '';

                    cov_1x18pfp9by.s[135]++;
                    if (vm.options.zoomLevel > 1) {
                        cov_1x18pfp9by.b[16][0]++;
                        cov_1x18pfp9by.s[136]++;

                        vm.zoomImageEl.style.width = trackerW * vm.options.zoomLevel + 'px';
                        cov_1x18pfp9by.s[137]++;
                        vm.zoomImageEl.style.height = trackerH * vm.options.zoomLevel + 'px';
                    } else {
                        cov_1x18pfp9by.b[16][1]++;
                        cov_1x18pfp9by.s[138]++;

                        if ((cov_1x18pfp9by.b[18][0]++, vm.zoomImageEl.offsetWidth <= maskW) || (cov_1x18pfp9by.b[18][1]++, vm.zoomImageEl.offsetHeight <= maskH)) {
                            cov_1x18pfp9by.b[17][0]++;
                            cov_1x18pfp9by.s[139]++;

                            //landscape
                            if (vm.zoomImageEl.offsetWidth / vm.zoomImageEl.offsetHeight > 1) {
                                cov_1x18pfp9by.b[19][0]++;
                                cov_1x18pfp9by.s[140]++;

                                vm.zoomImageEl.style.height = maskH * 1.5 + 'px';
                                cov_1x18pfp9by.s[141]++;
                                vm.zoomImageEl.style.width = '';
                            }
                            // portrait
                            else {
                                    cov_1x18pfp9by.b[19][1]++;
                                    cov_1x18pfp9by.s[142]++;

                                    vm.zoomImageEl.style.width = maskW * 1.5 + 'px';
                                    cov_1x18pfp9by.s[143]++;
                                    vm.zoomImageEl.style.height = '';
                                }
                        } else {
                            cov_1x18pfp9by.b[17][1]++;
                        }
                    }

                    cov_1x18pfp9by.s[144]++;
                    zoomImgW = vm.zoomImageEl.offsetWidth;
                    cov_1x18pfp9by.s[145]++;
                    zoomImgH = vm.zoomImageEl.offsetHeight;
                    cov_1x18pfp9by.s[146]++;
                    setLensSize();
                }

                function setZoomImagePosition(e) {
                    cov_1x18pfp9by.f[23]++;
                    cov_1x18pfp9by.s[147]++;

                    e.preventDefault();
                    var te = (cov_1x18pfp9by.s[148]++, (cov_1x18pfp9by.b[20][0]++, e.type === 'touchmove') && (cov_1x18pfp9by.b[20][1]++, e.touches) && (cov_1x18pfp9by.b[20][2]++, e.touches[0]));

                    cov_1x18pfp9by.s[149]++;
                    evPosX = (cov_1x18pfp9by.b[21][0]++, te) && (cov_1x18pfp9by.b[21][1]++, te.pageX) || (cov_1x18pfp9by.b[21][2]++, e.pageX);
                    cov_1x18pfp9by.s[150]++;
                    evPosY = (cov_1x18pfp9by.b[22][0]++, te) && (cov_1x18pfp9by.b[22][1]++, te.pageY) || (cov_1x18pfp9by.b[22][2]++, e.pageY);

                    cov_1x18pfp9by.s[151]++;
                    setLensPosition();

                    cov_1x18pfp9by.s[152]++;
                    if (vm.options.method === 'lens') {
                        cov_1x18pfp9by.b[23][0]++;
                        cov_1x18pfp9by.s[153]++;

                        trackLens();
                    }
                    // pointer
                    else {
                            cov_1x18pfp9by.b[23][1]++;
                            cov_1x18pfp9by.s[154]++;

                            trackPointer();
                        }
                }

                function trackLens() {
                    cov_1x18pfp9by.f[24]++;

                    var posX = (cov_1x18pfp9by.s[155]++, [(zoomImgW - maskW + lensW * 1 / zoomLevelRatio) * [lensPosX / trackerW]]);
                    var posY = (cov_1x18pfp9by.s[156]++, [(zoomImgH - maskH + lensH * 1 / zoomLevelRatio) * [lensPosY / trackerH]]);
                    cov_1x18pfp9by.s[157]++;
                    vm.zoomImageEl.style.transform = 'translate3d(' + posX * -1 + 'px,' + posY * -1 + 'px,0)';
                }

                function trackPointer() {
                    cov_1x18pfp9by.f[25]++;

                    var posX = (cov_1x18pfp9by.s[158]++, [(zoomImgW - maskW) * [(evPosX - trackerL) / trackerW]]);
                    var posY = (cov_1x18pfp9by.s[159]++, [(zoomImgH - maskH) * [(evPosY - trackerT) / trackerH]]);

                    // Touch Fixes
                    cov_1x18pfp9by.s[160]++;
                    posX = evPosX < trackerL ? (cov_1x18pfp9by.b[24][0]++, 0) : (cov_1x18pfp9by.b[24][1]++, posX);
                    cov_1x18pfp9by.s[161]++;
                    posY = evPosY < trackerT ? (cov_1x18pfp9by.b[25][0]++, 0) : (cov_1x18pfp9by.b[25][1]++, posY);
                    cov_1x18pfp9by.s[162]++;
                    posX = evPosX > trackerL + trackerW ? (cov_1x18pfp9by.b[26][0]++, zoomImgW - maskW) : (cov_1x18pfp9by.b[26][1]++, posX);
                    cov_1x18pfp9by.s[163]++;
                    posY = evPosY > trackerT + trackerH ? (cov_1x18pfp9by.b[27][0]++, zoomImgH - maskH) : (cov_1x18pfp9by.b[27][1]++, posY);

                    cov_1x18pfp9by.s[164]++;
                    vm.zoomImageEl.style.transform = 'translate3d(' + posX * -1 + 'px,' + posY * -1 + 'px,0)';
                }

                function setLensSize() {
                    cov_1x18pfp9by.f[26]++;
                    cov_1x18pfp9by.s[165]++;

                    zoomLevelRatio = trackerW / zoomImgW;
                    cov_1x18pfp9by.s[166]++;
                    lensW = maskW * zoomLevelRatio;
                    cov_1x18pfp9by.s[167]++;
                    lensH = maskH * zoomLevelRatio;
                    cov_1x18pfp9by.s[168]++;
                    vm.zoomLens.style.width = lensW + 'px';
                    cov_1x18pfp9by.s[169]++;
                    vm.zoomLens.style.height = lensH + 'px';
                }

                function setLensPosition() {
                    cov_1x18pfp9by.f[27]++;

                    function getWindow(elem) {
                        cov_1x18pfp9by.f[28]++;
                        cov_1x18pfp9by.s[170]++;

                        return isWindow(elem) ? (cov_1x18pfp9by.b[28][0]++, elem) : (cov_1x18pfp9by.b[28][1]++, (cov_1x18pfp9by.b[29][0]++, elem.nodeType === 9) && (cov_1x18pfp9by.b[29][1]++, elem.defaultView));
                    }

                    function isWindow(obj) {
                        cov_1x18pfp9by.f[29]++;
                        cov_1x18pfp9by.s[171]++;

                        return (cov_1x18pfp9by.b[30][0]++, obj != null) && (cov_1x18pfp9by.b[30][1]++, obj === obj.window);
                    }

                    var doc = (cov_1x18pfp9by.s[172]++, (cov_1x18pfp9by.b[31][0]++, vm.zoomTracker) && (cov_1x18pfp9by.b[31][1]++, vm.zoomTracker.ownerDocument));
                    var win = (cov_1x18pfp9by.s[173]++, getWindow(doc));
                    var docElem = (cov_1x18pfp9by.s[174]++, doc.documentElement);

                    var trackerPos = (cov_1x18pfp9by.s[175]++, vm.zoomTracker.getBoundingClientRect());
                    var trackerT = (cov_1x18pfp9by.s[176]++, trackerPos.top + win.pageYOffset - docElem.clientTop);
                    var trackerL = (cov_1x18pfp9by.s[177]++, trackerPos.left + win.pageXOffset - docElem.clientLeft);

                    cov_1x18pfp9by.s[178]++;
                    lensPosX = evPosX - trackerL - lensW * 0.5;
                    cov_1x18pfp9by.s[179]++;
                    lensPosY = evPosY - trackerT - lensH * 0.5;

                    cov_1x18pfp9by.s[180]++;
                    lensPosX = lensPosX > trackerW - lensW ? (cov_1x18pfp9by.b[32][0]++, trackerW - lensW) : (cov_1x18pfp9by.b[32][1]++, lensPosX);
                    cov_1x18pfp9by.s[181]++;
                    lensPosX = lensPosX < 0 ? (cov_1x18pfp9by.b[33][0]++, 0) : (cov_1x18pfp9by.b[33][1]++, lensPosX);

                    cov_1x18pfp9by.s[182]++;
                    lensPosY = lensPosY > trackerH - lensH ? (cov_1x18pfp9by.b[34][0]++, trackerH - lensH) : (cov_1x18pfp9by.b[34][1]++, lensPosY);
                    cov_1x18pfp9by.s[183]++;
                    lensPosY = lensPosY < 0 ? (cov_1x18pfp9by.b[35][0]++, 0) : (cov_1x18pfp9by.b[35][1]++, lensPosY);

                    cov_1x18pfp9by.s[184]++;
                    vm.zoomLens.style.transform = 'translate3d(' + lensPosX + 'px,' + lensPosY + 'px,0)';
                }

                function updateThumbsPos() {
                    cov_1x18pfp9by.f[30]++;
                    cov_1x18pfp9by.s[185]++;

                    if ((cov_1x18pfp9by.b[37][0]++, vm.images.length <= 1) || (cov_1x18pfp9by.b[37][1]++, vm.images.length < vm.options.thumbCol)) {
                        cov_1x18pfp9by.b[36][0]++;
                        cov_1x18pfp9by.s[186]++;

                        return;
                    } else {
                        cov_1x18pfp9by.b[36][1]++;
                    }

                    var selectedIndex = (cov_1x18pfp9by.s[187]++, getSelectedIndex());
                    var isInView = (cov_1x18pfp9by.s[188]++, (cov_1x18pfp9by.b[38][0]++, vm.thumbsPos + vm.options.thumbCol > selectedIndex) && (cov_1x18pfp9by.b[38][1]++, vm.thumbsPos < selectedIndex));
                    cov_1x18pfp9by.s[189]++;
                    if (isInView) {
                        cov_1x18pfp9by.b[39][0]++;
                        cov_1x18pfp9by.s[190]++;

                        scrollThumbs(vm.thumbsPos);
                        cov_1x18pfp9by.s[191]++;
                        return;
                    } else {
                        cov_1x18pfp9by.b[39][1]++;
                    }
                    cov_1x18pfp9by.s[192]++;
                    scrollThumbs(selectedIndex);
                }

                function getSelectedIndex() {
                    cov_1x18pfp9by.f[31]++;
                    cov_1x18pfp9by.s[193]++;

                    for (var i = 0; i < vm.images.length; i++) {
                        cov_1x18pfp9by.s[194]++;

                        if (vm.images[i].medium === vm.mainImage.medium) {
                            cov_1x18pfp9by.b[40][0]++;
                            cov_1x18pfp9by.s[195]++;

                            return i;
                        } else {
                            cov_1x18pfp9by.b[40][1]++;
                        }
                    }
                }

                function zoomStateEnable() {
                    cov_1x18pfp9by.f[32]++;
                    cov_1x18pfp9by.s[196]++;

                    $scope.$evalAsync(function () {
                        cov_1x18pfp9by.f[33]++;
                        cov_1x18pfp9by.s[197]++;

                        vm.zoomActive = true;
                    });
                }

                function zoomStateDisable() {
                    cov_1x18pfp9by.f[34]++;
                    cov_1x18pfp9by.s[198]++;

                    $scope.$evalAsync(function () {
                        cov_1x18pfp9by.f[35]++;
                        cov_1x18pfp9by.s[199]++;

                        vm.zoomActive = false;
                    });
                }

                function watchSelectedModel(newVal, oldVal) {
                    cov_1x18pfp9by.f[36]++;
                    cov_1x18pfp9by.s[200]++;

                    if ((cov_1x18pfp9by.b[42][0]++, angular.isDefined(newVal)) && (cov_1x18pfp9by.b[42][1]++, newVal !== oldVal)) {
                        cov_1x18pfp9by.b[41][0]++;
                        cov_1x18pfp9by.s[201]++;

                        vm.mainImage = newVal;
                        cov_1x18pfp9by.s[202]++;
                        updateThumbsPos();
                    } else {
                        cov_1x18pfp9by.b[41][1]++;
                    }
                }

                function watchSelectedIndex(newVal, oldVal) {
                    cov_1x18pfp9by.f[37]++;
                    cov_1x18pfp9by.s[203]++;

                    if ((cov_1x18pfp9by.b[44][0]++, angular.isDefined(newVal)) && (cov_1x18pfp9by.b[44][1]++, newVal !== oldVal)) {
                        cov_1x18pfp9by.b[43][0]++;
                        cov_1x18pfp9by.s[204]++;

                        vm.mainImage = vm.images[newVal];
                        cov_1x18pfp9by.s[205]++;
                        updateThumbsPos();
                    } else {
                        cov_1x18pfp9by.b[43][1]++;
                    }
                }

                function updateMainImage(image) {
                    cov_1x18pfp9by.f[38]++;
                    cov_1x18pfp9by.s[206]++;

                    console.log(image);
                    cov_1x18pfp9by.s[207]++;
                    vm.largeImageLoading = true;
                    cov_1x18pfp9by.s[208]++;
                    vm.mainImage = image;

                    // Pause Watchers
                    cov_1x18pfp9by.s[209]++;
                    deRegisterWatchers();

                    cov_1x18pfp9by.s[210]++;
                    $scope.selectedModel = angular.copy(vm.mainImage);
                    cov_1x18pfp9by.s[211]++;
                    $scope.selectedIndex = vm.images.indexOf(vm.mainImage);

                    // Resume watchers
                    cov_1x18pfp9by.s[212]++;
                    registerWatchers();
                }

                function registerWatchers() {
                    cov_1x18pfp9by.f[39]++;
                    cov_1x18pfp9by.s[213]++;

                    unWatchSelectedIndex = $scope.$watch('selectedIndex', watchSelectedIndex, true);
                    cov_1x18pfp9by.s[214]++;
                    unWatchSelectedModel = $scope.$watch('selectedModel', watchSelectedModel, true);
                }

                function deRegisterWatchers() {
                    cov_1x18pfp9by.f[40]++;
                    cov_1x18pfp9by.s[215]++;

                    unWatchSelectedModel();
                    cov_1x18pfp9by.s[216]++;
                    unWatchSelectedIndex();
                }

                function largeImageLoaded() {
                    cov_1x18pfp9by.f[41]++;
                    cov_1x18pfp9by.s[217]++;

                    vm.largeImageLoading = false;
                    cov_1x18pfp9by.s[218]++;
                    initSizes();
                }

                cov_1x18pfp9by.s[219]++;
                angular.element(window).on('resize', function () {
                    cov_1x18pfp9by.f[42]++;
                    cov_1x18pfp9by.s[220]++;

                    update();
                });

                cov_1x18pfp9by.s[221]++;
                if ($window.Ps) {
                    cov_1x18pfp9by.b[45][0]++;
                    cov_1x18pfp9by.s[222]++;

                    angular.element(document).on('ps-scroll-y', function () {
                        cov_1x18pfp9by.f[43]++;
                        cov_1x18pfp9by.s[223]++;

                        initSizes();
                    });
                } else {
                    cov_1x18pfp9by.b[45][1]++;
                }

                cov_1x18pfp9by.s[224]++;
                $scope.$watch(function () {
                    cov_1x18pfp9by.f[44]++;
                    cov_1x18pfp9by.s[225]++;

                    return {
                        left: vm.zoomTracker.getBoundingClientRect().left + $window.scrollX,
                        top: vm.zoomTracker.getBoundingClientRect().top + $window.scrollY
                    };
                }, function (newVal, oldVal) {
                    cov_1x18pfp9by.f[45]++;
                    cov_1x18pfp9by.s[226]++;

                    if ((cov_1x18pfp9by.b[47][0]++, angular.isDefined(newVal)) && (cov_1x18pfp9by.b[47][1]++, newVal !== oldVal)) {
                        cov_1x18pfp9by.b[46][0]++;
                        cov_1x18pfp9by.s[227]++;

                        update();
                    } else {
                        cov_1x18pfp9by.b[46][1]++;
                    }
                }, true);

                cov_1x18pfp9by.s[228]++;
                $scope.$watch('wipImageZoom', function (newVal, oldVal) {
                    cov_1x18pfp9by.f[46]++;
                    cov_1x18pfp9by.s[229]++;

                    if ((cov_1x18pfp9by.b[49][0]++, angular.isDefined(newVal)) && (cov_1x18pfp9by.b[49][1]++, newVal !== oldVal)) {
                        cov_1x18pfp9by.b[48][0]++;
                        cov_1x18pfp9by.s[230]++;

                        init();
                        cov_1x18pfp9by.s[231]++;
                        update();
                    } else {
                        cov_1x18pfp9by.b[48][1]++;
                    }
                }, true);
            }]
        };
    }

    function wipImageZoomLensDirective() {
        cov_1x18pfp9by.f[47]++;
        cov_1x18pfp9by.s[232]++;

        return {
            restrict: 'EA',
            require: '^wipImageZoom',
            link: function link(scope, element, attrs, ctrl) {
                cov_1x18pfp9by.f[48]++;
                cov_1x18pfp9by.s[233]++;

                ctrl.zoomLens = element[0];
            }
        };
    }

    function wipImageZoomTrackerDirective() {
        cov_1x18pfp9by.f[49]++;
        cov_1x18pfp9by.s[234]++;

        return {
            restrict: 'EA',
            require: '^wipImageZoom',
            link: function link(scope, element, attrs, ctrl) {
                cov_1x18pfp9by.f[50]++;
                cov_1x18pfp9by.s[235]++;

                ctrl.zoomTracker = element[0];
            }
        };
    }

    function wipImageZoomMaskDirective() {
        cov_1x18pfp9by.f[51]++;
        cov_1x18pfp9by.s[236]++;

        return {
            restrict: 'EA',
            require: '^wipImageZoom',
            link: function link(scope, element, attrs, ctrl) {
                cov_1x18pfp9by.f[52]++;
                cov_1x18pfp9by.s[237]++;

                ctrl.zoomMaskEl = element[0];
            }
        };
    }

    function wipImageZoomImageDirective() {
        cov_1x18pfp9by.f[53]++;
        cov_1x18pfp9by.s[238]++;

        return {
            restrict: 'EA',
            require: '^wipImageZoom',
            link: function link(scope, element, attrs, ctrl) {
                cov_1x18pfp9by.f[54]++;
                cov_1x18pfp9by.s[239]++;

                ctrl.zoomImageEl = element[0];
            }
        };
    }

    function wipImageZoomThumbsDirective() {
        cov_1x18pfp9by.f[55]++;
        cov_1x18pfp9by.s[240]++;

        return {
            restrict: 'EA',
            require: '^wipImageZoom',
            template: '<div class="thumbs-wrapper" ng-swipe-left="vm.nextThumb()" ng-swipe-right="vm.prevThumb()">\n    <div class="thumbs" >\n        <div class="thumb-wrapper" ng-repeat="image in vm.images">\n            <img ng-src="{{image.thumb}}" ng-click="vm.updateMainImage(image)"\n                 ng-class="{\'selected\': vm.mainImage.thumb === image.thumb}">\n        </div>\n    </div>\n</div>\n<div class="prev-button"\n     ng-if="vm.thumbsPos !== 0 && vm.images.length > vm.options.thumbCol"\n     ng-click="vm.prevThumb()"\n     ng-bind-html="vm.options.prevThumbButton">Prev\n</div>\n<div class="next-button"\n     ng-if="vm.thumbsPos !== vm.maxPosX && vm.images.length > vm.options.thumbCol"\n     ng-click="vm.nextThumb()"\n     ng-bind-html="vm.options.nextThumbButton">Next\n</div>',
            link: function link(scope, element, attrs, ctrl) {
                cov_1x18pfp9by.f[56]++;
                cov_1x18pfp9by.s[241]++;

                ctrl.thumbsWrapper = element[0].getElementsByClassName('thumbs-wrapper')[0];
                cov_1x18pfp9by.s[242]++;
                ctrl.thumbsEl = element[0].getElementsByClassName('thumbs')[0];
                cov_1x18pfp9by.s[243]++;
                ctrl.initThumbs();
            }
        };
    }

    function imageOnLoadDirective($log) {
        cov_1x18pfp9by.f[57]++;
        cov_1x18pfp9by.s[244]++;

        return {
            restrict: 'A',
            link: function link(scope, element, attrs) {
                cov_1x18pfp9by.f[58]++;
                cov_1x18pfp9by.s[245]++;

                element[0].addEventListener('load', function () {
                    cov_1x18pfp9by.f[59]++;
                    cov_1x18pfp9by.s[246]++;

                    scope.$apply(attrs.imageOnLoad);
                }, false);
                cov_1x18pfp9by.s[247]++;
                element[0].addEventListener('error', function () {
                    cov_1x18pfp9by.f[60]++;
                    cov_1x18pfp9by.s[248]++;

                    $log.warn('image could not be loaded');
                });
            }
        };
    }
})();