'use strict';

var cov_trjt67hzy = function () {
  var path = '/home/coloristic/shop.coloristic.ro/src/app/product-page/routes.js',
      hash = '3102238a0c2671531a13fa09e6a2557182a6d52f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/coloristic/shop.coloristic.ro/src/app/product-page/routes.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 9,
          column: 5
        }
      },
      '1': {
        start: {
          line: 4,
          column: 4
        },
        end: {
          line: 8,
          column: 9
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 3,
            column: 10
          },
          end: {
            line: 3,
            column: 11
          }
        },
        loc: {
          start: {
            line: 3,
            column: 28
          },
          end: {
            line: 9,
            column: 3
          }
        },
        line: 3
      }
    },
    branchMap: {},
    s: {
      '0': 0,
      '1': 0
    },
    f: {
      '0': 0
    },
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_trjt67hzy.s[0]++;
angular.module('app').config(function ($stateProvider) {
  cov_trjt67hzy.f[0]++;
  cov_trjt67hzy.s[1]++;

  $stateProvider.state('product', {
    url: '/produs/:slug',
    component: 'product'
  });
});